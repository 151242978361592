<template>
  <router-link to="/our-solutions" class="panel transition-y">
    <div class="panel-img">
      <img :src="src" alt="">
    </div>
    <div class="panel-text-container">
      <p class="to-translate" :data-en="en" :data-de="de">{{ text }}</p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'WordingView',
  props: {
    text: String,
    src: String,
    en: String,
    de: String
  }
}
</script>
