export default function maxHeight (arrEl) {
  if (window.innerWidth > 0) {
    let maxHeight = 0
    arrEl.forEach(box => {
      const boxHeight = box.clientHeight
      if (boxHeight > maxHeight) {
        maxHeight = boxHeight
      }
    })
    arrEl.forEach(box => {
      box.style.minHeight = `calc(${maxHeight}px + 20px)`
    })
  } else {
    arrEl.forEach(box => {
      box.style.minHeight = '100%'
    })
  }
}