<template>
  <div class="avis-display">
    <div class="avis-container" :style="transformStyle">
      <AvisComponent
        v-for="(item, index) in AvisItems"
        :key="index"
        :name="item.name"
        :linkedin="item.linkedin"
        :job="item.job"
        :quote="item.quote"
        :dataIndex="`${index}`"
        :QuoteDe="item.quoteDe"
        :QuoteEn="item.quoteEn"
        :JobDe="item.jobDe"
        :JobEn="item.jobEn"
        :google="item.google ? true : false"
        @click="changeIndex(index)"
        :class="{ 'active': currentIndex === index || isMobile }"
      />
    </div>
    <div class="arrows" v-if="arrows">
      <svg
        @click="changeIndex(currentIndex - 1)"
        data-index="-1"
        xmlns="http://www.w3.org/2000/svg"
        width="68.74"
        height="78.56"
        viewBox="0 0 68.74 78.56"
      >
        <path
          id="iconmonstr-arrow-30"
          d="M30.885,0H1.5L40.859,39.28,1.5,78.56H30.885L70.24,39.28Z"
          transform="translate(-1.5)"
          fill="#f7a600"
        />
      </svg>
      <svg
        @click="changeIndex(currentIndex + 1)"
        data-index="1"
        xmlns="http://www.w3.org/2000/svg"
        width="68.74"
        height="78.56"
        viewBox="0 0 68.74 78.56"
      >
        <path
          id="iconmonstr-arrow-30"
          d="M30.885,0H1.5L40.859,39.28,1.5,78.56H30.885L70.24,39.28Z"
          transform="translate(-1.5)"
          fill="#f7a600"
        />
      </svg>
    </div>
  </div>
</template>
<script setup>
import AvisComponent from "./AvisComponent.vue";
import { ref, onMounted, onBeforeUnmount } from "vue";

const currentIndex = ref(2);
const avisArr = ref(0);
const transformStyle = ref("");
const isMobile = ref(false);

const changeIndex = (_i) => {
  if (_i < 0) {
    currentIndex.value = avisArr.value - 1;
  } else if (_i >= avisArr.value) {
    currentIndex.value = 0;
  } else {
    currentIndex.value = _i;
  }
  changeStyle(currentIndex.value);
  console.log(currentIndex.value);
};

const changeStyle = (_i) => {
  // Applique le style de transformation seulement si isMobile est false
  if (!isMobile.value) {
    transformStyle.value = `transform: translateX(-${_i * 71}vw)`;
  } else {
    // Réinitialise le style si isMobile est true
    transformStyle.value = "";
  }
};

const assignAvisArr = () => {
  avisArr.value = document.querySelectorAll(".avis-container .avis").length;
};

const checkWindowSize = () => {
  // Utilise directement window.innerWidth ici pour vérifier la condition
  isMobile.value = window.innerWidth < 800;
  // Applique le style en fonction de la nouvelle valeur de isMobile
  changeStyle(currentIndex.value);
};

onMounted(() => {
  assignAvisArr();
  changeIndex(currentIndex.value);
  checkWindowSize();
  // Met à jour pour utiliser une fonction référencée correctement
  window.addEventListener("load", () => checkWindowSize());
  window.addEventListener("resize", checkWindowSize);
});
onBeforeUnmount(() => {
  // Met à jour pour utiliser une fonction référencée correctement
  window.removeEventListener("load", () => checkWindowSize());
  window.removeEventListener("resize", checkWindowSize);
});
</script>
<script>
export default {
  name: "AvisContainer",
  components: AvisComponent,
  props: {
    arrows: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      AvisItems: [
        {
          name: "Samuel Libis",
          linkedin: "https://www.linkedin.com/in/samuellibis/",
          jobEn: "Head of Supercard & Clubs at Coop",
          quoteEn:
            "I was lucky to have Carine in my team during my experience as Head of CRM at Manor, as she was leading the Customer Service Products business unit. Thanks to excellent project management skills, she was able to successfully lead the migration of the gift card platform to a new solution and enable double-digit B2B business growth. Carine is a self-starter with a sharp entrepreneurial spirit. She has been an outstanding partner in the execution of the CRM strategy, as well as a true role model for her team. I wish Carine a lot of success for her future and I highly would like to recommend her to any new manager crossing her path.",
          dataIndex: "1",
          quote:
            "J’ai eu la chance d’avoir Carine dans mon équipe lorsque j’étais responsable CRM chez Manor, alors qu’elle dirigeait le département des produits du Service Clients.  \n" +
            "Grâce à ses compétences exceptionnelles en gestion de projet, elle a su parfaitement gérer la migration de la plateforme de cartes cadeaux vers une nouvelle solution et permettre une croissance à deux chiffres du secteur B2B.\n" +
            "Carine possède un grand sens de l’initiative et un esprit d’entreprise affûté. Elle a été une partenaire de premier ordre dans l'exécution de la stratégie CRM, ainsi qu’un véritable exemple à suivre pour son équipe.\n" +
            "J’adresse tous mes vœux de succès à Carine pour la suite de sa carrière et ne peux que la recommander vivement comme collaboratrice à tous les responsables d’équipes. \n",
          jobDe: "Leiter Supercard und Kundenclubs bei Coop",
          job: "Responsable Supercard et clubs clients chez Coop",
          quoteDe:
            "Ich hatte das Glück, dass Carine während meiner Zeit als Leiter CRM bei Manor meinem Team angehörte. Sie war damals für den Geschäftsbereich der Kundendienstprodukte verantwortlich. Dank ihrer ausgezeichneten Fähigkeiten im Projektmanagement führte sie erfolgreich die Migration der Plattform für Geschenkkarten auf eine neue Lösung durch und ermöglichte ein zweistelliges Wachstum des B2B-Business. Carine verfügt über eine sehr hohe Selbstmotivation und einen ausgeprägten Unternehmergeist. Sie hat sich sowohl als extrem zuverlässige Partnerin bei der Ausführung der CRM-Strategie als auch als echtes Vorbild für ihr Team hervorgetan. Ich wünsche Carine für ihre Zukunft viel Erfolg und kann sie jedem Manager als neue Kollegin nur wärmstens empfehlen.",
        },
        {
          name: "Marc Faeh",
          linkedin: "https://www.linkedin.com/in/marcfaeh/",
          jobEn:
            "Senior Business Solution Manager Multichannel Advisory & Mobile bei Zühlke Group",
          quoteEn:
            "Carine is a passionate B2B and B2C marketing professional with excellent leadership skills both as a line and as a project manager. I have worked on complex business & IT projects with Carine and have appreciated her attention to detail, dedication and her good understanding of the project stakeholder environment. It was always a pleasure to work with Carine: Even in tough and stressful times she keeps her humor and a positive attitude. In summary: I happily recommend Carine as a CRM professional with strong project execution capabilities.",
          dataIndex: "2",
          quote:
            "Carine est une professionnelle passionnée du marketing B2B et B2C avec des compétences remarquables en matière de direction, à la fois en tant que responsable de ligne et cheffe de projet. Dans le cadre des projets commerciaux et informatiques complexes sur lesquels nous avons travaillé avec Carine, j’ai apprécié son souci du détail, son engagement et son excellente compréhension de l’environnement des parties prenantes.    Travailler avec Carine a toujours été très agréable: elle garde son humour et une attitude positive, même dans les moments difficiles et stressants. En bref, je ne saurais que trop recommander Carine en tant que professionnelle CRM dotée de compétences solides en matière d’exécution de projets.",
          quoteDe:
            "Carine ist eine passionierte Fachfrau für B2B- und B2C-Marketing und verfügt sowohl als Linien- als auch als Projektmanagerin über ausgezeichnete Führungsqualitäten. Ich habe an komplexen Business- und IT-Projekten mit Carine zusammengearbeitet und lernte dabei ihre Gewissenhaftigkeit, ihr Engagement und ihr umfassendes Verständnis für die Anliegen der Projekt-Stakeholder schätzen. Es war mir immer ein Vergnügen, mit Carine zusammenzuarbeiten, da sie auch in stressigen Zeiten und unter Druck ihren Humor und ihre positive Einstellung nicht verliert. Ich fasse mich kurz: Ich kann Carine in jeder Hinsicht als CRM-Fachfrau empfehlen, die über solide Kompetenzen bei der Projektdurchführung beweist.",
          job: "Senior Business Solution Manager Multichannel Advisory & Mobile chez Zühlke Group",
          jobDe:
            "Senior Business Solution Manager Multichannel Advisory & Mobile bei Zühlke Group",
        },
        {
          name: "Tobias Byland",
          linkedin: "https://www.linkedin.com/in/tobiasbyland/",
          jobEn: "Data Scientist @ Baloise Insurance",
          quoteEn:
            "I had the good fortune of working with Carine at Manor for 4 years on various CRM projects (Reporting & BI development, customer data analysis). In this time I have come to know Carine as a leader who values the opinions of the people around her and who remains calm even when things get hectic. I also highly value her cooperative way of working and absolutely would love a chance to work together again.",
          dataIndex: "3",
          jobDe: "Senior Data Scientist bei Baloise Group",
          quoteDe:
            "Ich durfte bei Manor vier Jahre lang mit Carine an verschiedenen CRM-Projekten (Reporting & Business Intelligence Development, Kundendatenanalyse) arbeiten.\n" +
            "In dieser Zeit habe ich Carine als eine Führungspersönlichkeit kennen gelernt, die die Meinungen der Personen aus ihrem Umfeld wertschätzt und auch in turbulenten Situationen einen kühlen Kopf bewahrt.\n" +
            "Auch ihre kooperative Arbeitsweise hat mich sehr überzeugt und ich würde mich sehr glücklich schätzen, wieder mit ihr zusammenzuarbeiten.\n",
          job: "Data Scientist chez Baloise Group",
          quote:
            "J’ai eu le plaisir de travailler avec Carine chez Manor pendant 4 ans sur différents projets CRM (reporting et développement BI, analyse de données clientèle)." +
            "Durant cette période, Carine a démontré ses grandes qualités de dirigeante: elle est à l’écoute des personnes qui l’entourent et ne perd jamais son sang-froid, même lorsque la pression monte." +
            "J’apprécie énormément son approche collaborative et serais très heureux d’avoir de nouveau l’occasion de travailler avec elle.   ",
        },
        {
          name: "Monique Gebauer",
          linkedin: "https://www.linkedin.com/in/monique-gebauer-crm/",
          jobEn: "Director CRM & Analytics @ Manor AG",
          quoteEn:
            "Carine worked for many years as an employee at Manor and then started her own business with eurecap. She is a 100% reliable partner and manages her projects independently, efficiently and thoughtfully. In her external project leader role in our CRM department she combines her analytical skills very well with her business knowledge. Topics as B2B partner management and development, contract negotiations and technical implementations are perfectly handled. Carine's communication with all stakeholders is exemplary. I look forward to working with her in the future.",
          jobDe: "Director CRM & Analytics bei Manor AG",
          quoteDe:
            "Carine hat jahrelang als Angestellte bei Manor gearbeitet und sich dann mit eurecap selbstständig gemacht. Sie ist eine 100% zuverlässige Partnerin und leitet ihre Projekte selbstständig, effizient und zielgerichtet. In ihrer Rolle als externe Projektleiterin in unserer CRM-Abteilung verbindet sie ihre analytischen Fähigkeiten sehr gut mit ihrem Fachwissen. Themen wie B2B-Partnermanagement und -entwicklung, Vertragsverhandlungen und technische Implementierungen werden von ihr perfekt gemeistert. Carines Kommunikation mit allen Beteiligten ist vorbildlich. Ich freue mich darauf, auch in Zukunft mit ihr zusammenzuarbeiten.",
          job: "Directrice département CRM & Analytics chez Manor SA",
          quote:
            "Carine a travaillé pendant de nombreuses années comme employée chez Manor et a ensuite créé sa propre entreprise avec eurecap. Elle est une partenaire fiable à 100 % et gère ses projets de manière indépendante, efficace et intelligente. Dans son rôle de cheffe de projet externe au sein de notre département CRM, elle combine très bien ses compétences analytiques avec ses connaissances commerciales. Elle maitrise parfaitement la gestion et le développement de partenaires B2B, les négociations contractuelles et les implémentations techniques. La communication de Carine avec toutes les parties prenantes est exemplaire. Je me réjouis de travailler avec elle à l'avenir.",
        },
        {
          name: "Camille Roth",
          linkedin: "https://www.linkedin.com/in/camille-roth-868468106/",
          jobEn: "CRM Campaign Manager @ Manor AG",
          quoteEn:
            "Working with Carine was a great opportunity and a pleasure. Therefore, I fully recommend Carine and eurecap and I am looking forward to work with her again for the next project. Carine has led different marketing and CRM projects successfully. She aims to develop the products and the brand in general through targeted marketing activities, but also through technical developments and process improvements. She always had excellent relationship with suppliers, customers, internal and external stakeholders as well as colleagues. She doesn't just achieve goals, she has the ability of a true visionary leader who knows how to set goals and exploit market opportunities, taking into account all the key performance indicators and focusing on the customer journey and customer experience. She seeks to support and help to develop her team and peers professionally and personally. Thanks for everything eurecap !",
          jobDe: "CRM-Kampagnenmanagerin bei Manor AG",
          quoteDe:
            "Die Zusammenarbeit mit Carine war eine grossartige Chance und ein Vergnügen. Daher empfehle ich Carine und eurecap uneingeschränkt weiter und freue mich darauf, beim nächsten Projekt wieder mit ihr zu arbeiten. Carine hat verschiedene Marketing- und CRM-Projekte erfolgreich geleitet. Ihr Ziel ist es, die Produkte und die Marke im Allgemeinen durch gezielte Marketingaktivitäten, aber auch durch technische Entwicklungen und Prozessverbesserungen weiterzuentwickeln. Sie hatte immer hervorragende Beziehungen zu Lieferanten, Kunden, internen und externen Stakeholdern sowie Kollegen. Sie erreicht nicht nur Ziele, sondern verfügt über die Fähigkeiten einer ausgesprochenen visionären Führungspersönlichkeit, die es versteht, Ziele zu setzen und Marktchancen zu nutzen, wobei sie alle wichtigen Leistungsindikatoren berücksichtigt und sich auf die Customer Journey und das Kundenerlebnis konzentriert. Sie ist bestrebt, ihr Team und ihre Kollegen beruflich und persönlich zu unterstützen und zu fördern. Vielen Dank für alles, eurecap!",
          job: "Responsable campagnes CRM chez Manor SA",
          quote:
            "Travailler avec Carine a été une grande opportunité et un vrai plaisir. Je recommande donc pleinement Carine et eurecap et je me réjouis de travailler à nouveau avec elle pour le prochain projet. Carine a mené avec succès différents projets de marketing et gestion de la relation client. Elle vise à développer les produits et la marque en général par des activités de marketing ciblées, mais aussi par des développements techniques et des améliorations de processus. Elle a toujours entretenu d'excellentes relations avec les fournisseurs, les clients, les partenaires internes et externes ainsi qu'avec ses collègues. Elle ne se contente pas d'atteindre des objectifs, elle a la capacité d'un véritable leader visionnaire qui sait comment fixer des objectifs et exploiter les opportunités du marché, en tenant compte des indicateurs clés de performance et en se concentrant sur le parcours et l'expérience client. Elle sait soutenir et encourager son équipe et ses collègues, tant sur le plan professionnel que personnel. Merci pour tout, eurecap !",
          google: true
          },
      ],
    };
  },
};
</script>
