<template>
  <h3 class="to-translate" :data-en="textEn" :data-de="textDe">
    {{ text }}
  </h3>
</template>
<script>
export default {
  name: 'FooterTitle',
  props: {
    textEn: String,
    text: String,
    textDe: String
  }
}
</script>
<style lang="scss" scoped>
h3 {
  font-size: 20px;
  font-weight: 500;
}
</style>
