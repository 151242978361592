<template>
  <div class="solution-box transition-y">
    <div class="solution-svg-container">
      <img :src="src" alt="">
    </div>
    <h4 class="to-translate transition-x" :data-en="titleEn" :data-de="titleDe">{{ title }}</h4>
    <p class="to-translate transition-x" :data-en="textEn" :data-de="textDe">
      {{ paragraphe }}
    </p>
    <EnSavoirPlus :where="'/our-solutions'" />
  </div>
</template>
<script setup>
import EnSavoirPlus from '@/components/EnSavoirPlus.vue'
</script>
<script>
export default {
  name: 'HomeCards',
  props: {
    src: String,
    title: String,
    paragraphe: String,
    titleEn: String,
    titleDe: String,
    textEn: String,
    textDe: String
  }
}
</script>
