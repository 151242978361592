<template>
  <main>
    <HeroBanner
    class="references-hero-banner"
    :src="ReferencesHeroBanner"
    titleFirstPart="Des&nbsp;"
    titleSecondPart="moignages inspirants"
    paragraphe="Nous accompagnons les entreprises sur les étapes du parcours client, en alliant vision stratégique et excellence opérationelle."
    titleFirstPartEn="Inspiring&nbsp;"
    titleSecondPartEn="stimonials"
    paragrapheEn="We assist companies at every stage of the customer journey, combining strategic vision with operational excellence."
    titleFirstPartDe=""
    titleSecondPartDe="richte"
    paragrapheDe="Wir begleiten die Unternehmen bei den verschiedenen Etappen der Kundenbeziehung und vereinen dabei strategische Visionen mit operativer Effizienz."
    color="#F7A600"
    titleSecondPart1="t"
    titleSecondPart1En="t"
    titleSecondPart1De="Erfahrungsb"
    />
    <AvisContainer class="reference-avis" :arrow="true"/>
  </main>
</template>

<script>
import HeroBanner from '@/components/HeroBanner.vue'
import AvisContainer from '@/components/avis/AvisContainer.vue'
import imgObserver from '@/assets/js/ImgObserver'
export default {
  name: 'ReferencesView',
  components: {
    HeroBanner,
    AvisContainer
  },
  data () {
    return {
      ReferencesHeroBanner: require('@/assets/img/references-hero-banner.png')
    }
  },
  mounted () {
    imgObserver(document.querySelectorAll('img'))
  }
}
</script>