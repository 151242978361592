import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ContactView from '../views/ContactView.vue'
import OurSolutionsView from '../views/OurSolutionsView.vue'
import ReferenceView from '../views/ReferencesView.vue'
import cookieFooter from '@/views/footer-menu/CookieFooter.vue'
import policyFooter from '@/views/footer-menu/PolicyFooter.vue'
import impressumView from '@/views/footer-menu/ImpressumView.vue'
import animation from '@/assets/js/animation.js'
import translate from '@/assets/js/translation.js'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/references',
    name: 'reference',
    component: ReferenceView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/our-solutions',
    name: 'ourSolutions',
    component: OurSolutionsView
  },
  {
    path: '/cookie-policy',
    name: 'CookieView',
    component: cookieFooter
  },
  {
    path: '/privacy-policy',
    name: 'PolicyView',
    component: policyFooter
  },
  {
    path: '/publication-details',
    name: 'Impressum',
    component: impressumView
  }
]

// Configuration du router
const base_url = "/" // Define your base URL here
const router = createRouter({
  history: createWebHistory(base_url),
  routes
})

router.afterEach(() => {
  console.log('router.afterEach')
  translate(document.querySelector('header img.active').dataset.flag)
  console.log(document.querySelector('header img.active').dataset.flag)
  setTimeout(() => {
    animation()
  }, 400)
})

export default router
