<template>
   <router-link to="/" class="logo-container">
     <img src="@/assets/img/logo/eurecap-logo.svg" alt="" class="logo">
   </router-link>
   <router-link to="/" id="mini-logo-container">
    <svg class="mini-logo" xmlns="http://www.w3.org/2000/svg" width="50.886" height="78.916" viewBox="0 0 50.886 78.916">
      <g id="Groupe_98" data-name="Groupe 98" transform="translate(-445.332 -182.157)">
        <path id="Tracé_383" data-name="Tracé 383" d="M190.7,76H149.255a16.017,16.017,0,0,0,5.933,10.844,19.321,19.321,0,0,0,12.58,4.095q9.154,0,14.746-6.171l5.1,5.978A22.492,22.492,0,0,1,179,96.914a32.519,32.519,0,0,1-25.874-1.2,23.71,23.71,0,0,1-9.687-9.3,26.686,26.686,0,0,1-3.426-13.5,26.943,26.943,0,0,1,3.324-13.4,23.745,23.745,0,0,1,9.154-9.3,26.133,26.133,0,0,1,13.158-3.324A25.574,25.574,0,0,1,178.7,50.214a23.351,23.351,0,0,1,8.961,9.3A28.293,28.293,0,0,1,190.9,73.207,27.577,27.577,0,0,1,190.7,76ZM154.518,58.744a16.314,16.314,0,0,0-5.252,10.606h32.771A16.414,16.414,0,0,0,176.785,58.8a17.24,17.24,0,0,0-22.256-.045Z" transform="translate(305.322 162.037)"/>
        <path id="Tracé_384" data-name="Tracé 384" d="M163.085,23.29c4.685,0,2.756,13.748,0,19.522-2.756-5.774-4.673-19.522,0-19.522Z" transform="translate(308.042 158.867)" />
        <path id="Tracé_385" data-name="Tracé 385" d="M184.967,33.655c2.972,2.972-5.2,8.689-9.857,9.857,1.168-4.662,6.9-12.818,9.857-9.857Z" transform="translate(310.037 160.174)" />
        <path id="Tracé_386" data-name="Tracé 386" d="M141.775,33.655c2.972-2.972,8.689,5.2,9.857,9.857-4.662-1.168-12.818-6.9-9.857-9.857Z" transform="translate(305.474 160.174)" />
      </g>
    </svg>
  </router-link>
   <div id="hbg">
    <span></span>
    <span></span>
    <span></span>
   </div>
  <nav>
    <router-link to="/" class="to-translate" data-en="Home" data-de="Startseite">Accueil</router-link>
    <router-link to="/our-solutions" class="to-translate" data-en="Our Solutions" data-de="Unsere Lösungen">Nos Solutions</router-link>
    <router-link to="/references" class="to-translate" data-en="References" data-de="Referenzen">Références</router-link>
    <router-link to="/about" class="to-translate" data-en="About" data-de="Über uns">A propos</router-link>
    <router-link to="/contact" class="to-translate" data-en="Contact" data-de="Kontakt">Contact</router-link>
  </nav>

   <div id="translation-container">
     <img alt="" data-flag="de" src="../assets/img/flags/du-flag.svg"/>
     <img alt="" data-flag="fr" class="active" src="../assets/img/flags/fr-flag.svg"/>
     <img alt="" data-flag="en" src="../assets/img/flags/en-flag.svg"/>
   </div>
</template>

<script setup>
import { onMounted } from 'vue'
import translate from '@/assets/js/translation'

let flags = []

onMounted(() => {
  flags = document.querySelectorAll('#translation-container img')
  const localLang = localStorage.getItem('language')

  flags.forEach((btn) => {
    const language = btn.dataset.flag
    if (localLang === language) {
      flags.forEach((el) => el.classList.remove('active'))
      btn.classList.add('active')
      setTimeout(() => {
        translate(language)
      }, 1)
    }
    btn.addEventListener('click', (e) => {
      flags.forEach((flag) => flag.classList.remove('active'))
      e.preventDefault()
      btn.classList.add('active')
      translate(language)
      localStorage.setItem('language', language)
    })
  })

  const hbg = document.querySelector('header #hbg')
  const header = document.querySelector('header')
  const links = document.querySelectorAll('header nav a')

  hbg.addEventListener('click', () => {
    hbg.classList.toggle('active')
    header.classList.toggle('active')
  })

  document.addEventListener('click', (e) => {
    if (header.classList.contains('active') && e.target !== hbg && !header.contains(e.target)) {
      hbg.click()
    }
  })

  links.forEach(link => {
    link.addEventListener('click', () => {
      hbg.click()
    })
  })
})

</script>
<script>
export default {
  name: 'HeaderView'
}
</script>