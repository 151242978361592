<template>
    <nav>
      <router-link to="/publication-details" data-en="Publication details" class="to-translate" data-de="Impressum">Impressum</router-link>
      <router-link to="/cookie-policy" data-en="Cookies policy" class="to-translate" data-de="Cookie-Richtlinie">Politique en matière  de cookies</router-link>
      <router-link to="/privacy-policy" data-en="Privacy policy" class="to-translate" data-de="Datenschutzrichtlinie">Politique de confidentialité</router-link>
    </nav>
    <router-link to="/">
      <img src="../../assets/img/logo/eurecap-logo.svg" alt="" class="logo"/>
    </router-link>
    <p class="all-rights-reserved to-translate" data-en="© 2023 EURECAP All rights reserved." data-de="© 2023 EURECAP Alle Rechte vorbehalten.">© 2023 EURECAP Tous droits réservés.</p>
</template>

<script>
import imgObserver from '@/assets/js/ImgObserver'
export default {
  name: 'FooterView',
  mounted () {
    imgObserver(document.querySelectorAll('img'))
  }
}
</script>
