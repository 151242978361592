<template>
  <div class="about-card">
    <h4 class="to-translate transition-x" :data-en="cardTitle.en" :data-de="cardTitle.de">{{ cardTitle.text }}</h4>
    <hr>
    <p class="to-translate transition-x" :data-en="cardDesc.en" :data-de="cardDesc.de">{{ cardDesc.text }}</p>
    <div class="about-card-text">
      <p class="to-translate transition-x" :data-en="cardListTitle.en" :data-de="cardListTitle.de">{{ cardListTitle.text }}</p>
      <div class="about-card-list">
        <p
          class="to-translate transition-x"
          v-for="(item, index) in cardList"
          :key="index"
          :data-en="item.en"
          :data-de="item.de"
        >
          {{ item.text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OurSolutionsCard',
  props: {
    cardTitle: Object,
    cardDesc: Object,
    cardListTitle: Object,
    cardList: Array
  }
}
</script>
<style lang="scss">
@import '../assets/scss/config/variables';
.about-card {
  padding: 45px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  &.about-card-blue {
    background: #98B4C9;
    color: white;
    hr {
      background: $black;
    }
  }
  &.about-card-orange {
    background: $orange;
    color: white;
    hr {
      background: $black;
    }
  }
  &.about-card-white {
    background:white;
    color: $black;
    svg, path{
      fill: $orange;
    }
    hr {
      background: $orange;
    }
  }
  h4 {
    font-weight: bold;
  }
  p {
    font-weight: 550;
  }
  hr {
    outline: 0;
    border: 0;
    width: 100%;
    height: 1px;
  }
  .about-card-text {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 15px;
    .about-card-list {
      display: flex;
      flex-direction: column;
      gap: 20px;
      p {
        display: flex;
        gap: 3px;
        svg {
          min-width: 20px;
          transform: translateY(30%) scale(1.2);
          -webkit-transform: translateY(30%) scale(1.2);
          object-fit: contain;
          path {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 800px) {
  .about-card {
    padding: 25px;
  }
}
</style>
