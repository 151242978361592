<template>
  <div class="transition" :class="{ 'hidden': isTransitioning }"></div>
</template>

<script>
export default {
  name: 'PagesTransition',
  data () {
    return {
      isTransitioning: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      setTimeout(() => {
        this.isTransitioning = true
      }, 150)
    })
  },
  beforeUnmount () {
    this.isTransitioning = false
  }
}
</script>

<style lang="scss">
div.transition {
  height: 100vh;
  background: white;
  transition: height 0.45s cubic-bezier(0,.85,.25,1);
}
.transition.hidden {
  height: 0 !important;
}
</style>
