<script setup>
import HeaderView from '@/components/HeaderVue.vue'
import FooterView from '@/components/footer/FooterVue.vue'
import translate from '@/assets/js/translation'
import PagesTransition from '@/components/PagesTransition.vue'
import aboutHero from '@/assets/img/about-hero-banner.png'
import OurSolutionsBanner from '@/assets/img/ourSolutions-hero-banner.png'
import ReferencesBanner from '@/assets/img/references-hero-banner.png'

import { onMounted } from 'vue'

const head = document.querySelector('head')
head.innerHTML += `
      <link rel="preload" href="${OurSolutionsBanner}" as="image">
      <link rel="preload" href="${aboutHero}" as="image">
      <link rel="preload" href="${ReferencesBanner}" as="image">
  `
onMounted(() => {
  const a = document.querySelectorAll('a')
  console.log(a)
  a.forEach(link => {
    link.addEventListener('click', (e) => {
      e.preventDefault()
      window.scrollTo(0, 0)
      translate(document.querySelector('header img.active').dataset.flag)
    })
  })
})
</script>

<template>
  <header>
    <HeaderView />
  </header>
    <pages-transition />
    <router-view />
  <footer>
    <FooterView />
  </footer>
</template>

<style lang="scss">
@import "./assets/scss/main.scss";
</style>