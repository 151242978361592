import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default function animation() {
// // Initialisez GSAP avec ScrollTrigger
gsap.registerPlugin(ScrollTrigger)
    // Sélectionnez les éléments X et Y que vous souhaitez animer
  const elementsX = document.querySelectorAll('.transition-x')
  const elementsY = document.querySelectorAll('.transition-y')
  const start = 'top 102%'
  const end = 'bottom 5%'
  const delay = 0
  console.log(elementsX, elementsY)
  // Parcourez les éléments X et ajoutez une animation pour chaque élément
  elementsX.forEach((element) => {
    gsap.to(element, {
      opacity: 1,
      x: 0, // Animation de -30px à 0px pour translateX
      filter: 'blur(0px)',
      duration: 0.4,
      delay: delay,
      scrollTrigger: {
        trigger: element,
        start: start, // Démarrer l'animation lorsque l'élément est à 80% du haut de la fenêtre
        end: end, // Arrêter l'animation lorsque l'élément est à 20% du bas de la fenêtre
        toggleActions: 'play reverse play reverse',
        ease: 'Power2.easeOut'
      }
    })
  })
  // Parcourez les éléments Y et ajoutez une animation pour chaque élément
  elementsY.forEach((element) => {
    gsap.to(element, {
      opacity: 1,
      y: 0, // Animation de -30px à 0px pour translateY
      filter: 'blur(0px)',
      duration: 0.4,
      delay:delay,
      scrollTrigger: {
        trigger: element,
        start: start, // Démarrer l'animation lorsque l'élément est à 80% du haut de la fenêtre
        end: end, // Arrêter l'animation lorsque l'élément est à 20% du bas de la fenêtre
        toggleActions: 'play reverse play reverse',
        ease: 'Power2.easeOut'
      }
    })
  })
}