<script>
/* eslint-disable */
import imgObserver from "@/assets/js/ImgObserver";
import TitleFooterMenu from "@/components/footer/TitleFooterMenu.vue";
export default {
  name: "PolicyFooter",
  mounted() {
    imgObserver(document.querySelectorAll("img"));
  },
};
</script>

<template>
  <div class="footer-menu">
    <h2
      class="to-translate"
      data-en="Privacy policy"
      data-de="Datenschutzrichtlinie"
    >
      Politique de confidentialité
    </h2>
    <p
      class="to-translate"
      data-de='In dieser Datenschutzerklärung erläutern wir, die eurecap GmbH (nachfolgend, wir oder uns), wie wir Personendaten erheben und sonst bearbeiten. Unter Personendaten werden alle Angaben verstanden, die sich auf eine bestimmte oder bestimmbare Person beziehen. Wenn Sie uns Personendaten anderer Personen (zum Beispiel Familienmitglieder, Daten von Arbeits-kollegen) zur Verfügung stellen, stellen Sie bitte sicher, dass diese Personen die vorliegende Datenschutzerklärung kennen und teilen Sie uns deren Personendaten nur mit, wenn Sie dies dürfen und wenn diese Personendaten korrekt sind. Diese Datenschutzerklärung ist auf die Anforderungen der EU-Datenschutz-Grundverordnung ("DSGVO") und des Schweizer Datenschutzgesetzes ("DSG") ausgelegt. Ob und inwieweit diese Gesetze anwendbar sind, hängt jedoch vom Einzelfall ab. Die verwendeten Begriffe sind nicht geschlechtsspezifisch.'
      data-en='In this privacy policy (this "Privacy Policy"), we, eurecap Ltd (hereinafter, we or us), explain how we collect and otherwise process personal data. 
      The term "personal data" or “data” refers to all information that relates to a specific or identifiable person. 
      If you provide us with the personal data of other persons (for example, family members, data of work colleagues), please make sure that these persons are aware of this Privacy Policy and only share their data with us if you are allowed to do so and if this personal data is correct.
      This Privacy Policy is aligned with the EU General Data Protection Regulation (“GDPR”) and the Swiss Data Protection Act (“DPA”). However, the application of these laws depends on each case.
      The terms used are not gender-specific.
      '
    >
      Dans la présente déclaration de protection des données (ci-après
      "déclaration"), nous, eurecap Sàrl (ci-après "nous"), expliquons comment
      nous collectons et traitons les données à caractère personnel.  Le terme
      "données à caractère personnel" ou "données" désigne toutes les
      informations qui se rapportent à une personne spécifique ou identifiable.
      Si vous nous fournissez les données personnelles d'autres personnes (par
      exemple, des membres de votre famille ou des collègues de travail),
      assurez-vous que ces personnes aient connaissance de la présente
      déclaration de protection des données et ne partagez leurs données avec
      nous que si vous êtes autorisés à le faire et si ces données
      personnellessont correctes. La présente déclaration protection des données
      est alignée sur le règlement général sur la protection des données de l'UE
      ("RGPD") et la loi suisse sur la protection des données ("LPD").
      Toutefois, l'application de ces lois dépend de chaque cas. Dans le présent
      document, les termes employés pour désigner des personnes ou des fonctions
      sont pris au sens générique; ils ont à la fois valeur d’un féminin et d’un
      masculin. 
    </p>
    <h4
      class="to-translate"
      data-en="Whom Can I Contact if I have questions about Data Privacy?"
      data-de="An wen kann ich mich bei Fragen zum Datenschutz wenden?"
    >
      A qui puis-je poser des questions concernant la protection de mes données
      ?
    </h4>
    <p
      class="to-translate"
      data-en="eurecap Ltd, Aeschengraben 29, CH-4051 Basel, is responsible for the data processing that we describe here unless otherwise specified in individual instances. If you have any data privacy concerns, you can communicate them to us at the following contact address: 
    Postal address: c/o OBC Suisse, Aeschengraben 29, CH-4051 Basel
    Email: info@eurecap.ch
    "
      data-de="Verantwortlich für die Datenbearbeitungen, die wir hier beschreiben, ist eurecap GmbH, Aeschengraben 29, 4051 Basel, soweit im Einzelfall nichts anderes angegeben ist. Wenn Sie datenschutzrechtliche Anliegen haben, können Sie uns diese an folgende Kontaktadresse mitteilen: 
    Postadresse: c/o OBC Suisse, Aeschengraben 29, CH-4051 Basel
    Email: info@eurecap.ch
     "
    >
      eurecap Sàrl, Aeschengraben 29, CH-4051 Bâle, est responsable du
      traitement des données que nous décrivons ici, sauf indication contraire
      dans des cas particuliers. Si vous avez des questions concernant la
      protection des données, vous pouvez nous les faire parvenir aux
      coordonnées suivantes : Adresse postale: c/o OBC Suisse, Aeschengraben 29,
      CH-4051 Bâle Email: info@eurecap.ch
    </p>
    <h4
      class="to-translate"
      data-en="Which data do we collect and process?
    "
      data-de="Welche Daten erheben wir?"
    >
      Quelles données collectons-nous et traitons-nous ?
    </h4>
    <p
      class="to-translate"
      data-en="We primarily process the personal data that we receive from our customers and other business partners in the course of our business relationship with them and other persons involved in it, or that we collect from their users in the operation of our website(s). To the extent permitted, we also collect certain data from publicly accessible sources (for example, debt collection registers, land registers, commercial registers, the press, and the Internet) or receive such data from public authorities and other third parties (such as credit agencies or address providers). 
      In addition to the data about you that you give us directly, the categories of personal data that we receive about you from third parties include, in particular, information from public registers, information that we learn in connection with official and legal proceedings, information relating to your professional functions and activities (for example, to enable us to enter into and process transactions with your employer with your assistance), information about you in correspondence and meetings with third parties, credit reports (to the extent that we process transactions with you personally), information about you provided to us by persons close to you (family, advisors, legal representatives, etc.) to enable us to enter into contracts with you, conclude or process agreements with you or involving you (for example, references), your address for deliveries, powers of attorney, information on compliance with legal requirements such as anti-money laundering and export restrictions, information from banks, insurance companies, (e.g. payments made, purchases made), personal data from the media and the Internet (if this is appropriate in the specific case, for example as part of an application, press review, marketing/sales, etc.). ), your addresses and, if applicable, interests and other socio-demographic data (for marketing), data in connection with the use of the website (for example, IP address, MAC address of the smartphone or computer, information about your device and settings, cookies, date and time of visit, pages viewed and content, functions used, referring website, location information).
      "
      data-de="Wir bearbeiten in erster Linie die Personendaten, die wir im Rahmen unserer Geschäftsbeziehung mit unseren Kunden und anderen Geschäftspartnern von diesen und weiteren daran beteiligten Personen erhalten oder die wir beim Betrieb unserer Websites von deren Nutzern erheben. Soweit dies erlaubt ist, entnehmen wir auch öffentlich zugänglichen Quellen (zum Beispiel Betreibungsregister, Grundbücher, Handelsregister, Presse, Internet) gewisse Daten, von Behörden und sonstigen Dritten (wie zum Beispiel Kreditauskunfteien, Adresshändler). 
    Nebst den Daten von Ihnen, die Sie uns direkt geben, umfassen die Kategorien von Personendaten, die wir von Dritten über Sie erhalten, insbesondere Angaben aus öffentlichen Registern, Angaben, die wir im Zusammenhang mit behördlichen und gerichtlichen Verfahren erfahren, Angaben im Zusammenhang mit ihren beruflichen Funktionen und Aktivitäten (damit wir zum Beispiel mit Ihrer Hilfe Geschäfte mit Ihrem Arbeitgeber abschliessen und abwickeln können), Angaben über Sie in Korrespondenz und Besprechungen mit Dritten, Bonitätsauskünfte (soweit wir mit Ihnen persönlich Geschäfte abwickeln), Angaben über Sie, die uns Personen aus Ihrem Umfeld (Familie, Berater, Rechtsvertreter, etc.) geben, damit wir Verträge mit Ihnen oder unter Einbezug von Ihnen abschliessen oder abwickeln können (zum Beispiel Referenzen), Ihre Adresse für Lieferungen, Vollmachten, Angaben zur Einhaltung gesetzlicher Vorgaben wie etwa der Geldwäschereibekämpfung und Exportrestriktionen, Angaben von Banken, Versicherungen, Vertriebs- und anderen Vertragspartnern von uns zur Inanspruchnahme oder Erbringung von Leistungen durch Sie (zum  Beispiel erfolgte Zahlungen, erfolgte Käufe), Angaben aus Medien und Internet zu Ihrer Person (soweit dies im konkreten Fall angezeigt ist, zum Beispiel im Rahmen einer Bewerbung, Presseschau, Marketing/Verkauf, etc.), Ihre Adressen und ggf. Interessen und weitere soziodemographische Daten (für Marketing), Daten im Zusammenhang mit der Benutzung der Website (zum Beispiel IP-Adresse, MAC-Adresse des Smartphones oder Computers, Angaben zu Ihrem Gerät und Einstellungen, Cookies, Datum und Zeit des Besuchs, abgerufene Seiten und Inhalte, benutzte Funktionen, verweisende Website, Standortangaben).
    "
    >
      Nous traitons principalement les données personnelles que nous recevons de
      nos clients et autres partenaires commerciaux dans le cadre de notre
      relation commerciale avec eux et d'autres personnes impliquées dans cette
      relation, ou que nous collectons auprès de leurs utilisateurs dans le
      cadre de l'exploitation de notre (nos) site(s) web et d'autres programmes.
      Dans la mesure où cela est autorisé, nous collectons également certaines
      données à partir de sources accessibles au public (par exemple, registre
      des poursuites, registre foncier, registre du commerce, presse et
      Internet) ou recevons ces données d'autorités publiques et d'autres tiers
      (tels que des agences de crédit ou des fournisseurs d'adresses).  Outre
      les données vous concernant que vous nous communiquez directement, les
      catégories de données que nous recevons de tiers comprennent notamment des
      informations provenant de registres publics, des informations dont nous
      avons connaissance dans le cadre de procédures officielles et judiciaires,
      des informations relatives à vos fonctions et activités professionnelles
      (par exemple, pour nous permettre de conclure et de traiter des
      transactions avec votre employeur avec votre aide), des informations vous
      concernant dans la correspondance et les réunions avec des tiers, des
      évaluations de crédit (dans la mesure où nous effectuons des transactions
      avec vous personnellement), des informations vous concernant fournies par
      des personnes qui vous sont proches (famille, conseillers, représentants
      légaux, etc.) pour nous permettre de conclure des contrats ou des accords
      avec des tiers ou avec vous, respectivement des contrats ou des accords
      vous concernant (par exemple l'obtention de références), votre adresse
      pour les livraisons, des procurations, des informations sur le respect
      d'exigences légales telles que la lutte contre le blanchiment d'argent et
      les restrictions à l'exportation, des informations provenant de banques,
      de compagnies d'assurance (par exemple, les paiements et les achats
      effectués), des données personnelles provenant des médias et d'Internet
      (si cela est approprié dans le cas spécifique, par exemple dans le cadre
      d'une candidature, d'une revue de presse, du marketing/de la vente, etc.
      ), vos adresses et, le cas échéant, vos intérêts et autres données
      sociodémographiques (à des fins de marketing), les données liées à
      l'utilisation du site web (par exemple, adresse IP, adresse MAC du
      smartphone ou de l'ordinateur, informations sur votre appareil et ses
      paramètres, cookies, date et heure de la visite, pages consultées et
      contenu, fonctions utilisées, site web de référence, informations sur la
      localisation).
    </p>
    <h4
      class="to-translate"
      data-en="For what purpose do we use your data?"
      data-de="Wozu verwenden wir Ihre Daten?"
    >
      Dans quel but utilisons-nous vos données ?
    </h4>
    <p
      class="to-translate"
      data-de="Wir verwenden die von uns erhobenen Personendaten in erster Linie, um unsere Verträge mit unseren Kunden und Geschäftspartnern abzuschliessen und abzuwickeln, so insbesondere im Rahmen der folgenden Tätigkeit:
die Beratung und Projektleitung im Bereich Kundenmarketing.
Im Rahmen dieser Tätigkeit erheben wir Personendaten für Geschäfte mit unseren Kunden und den Einkauf von Produkten und Dienstleistungen von unseren Lieferanten und Subunternehmern, sowie um unseren gesetzlichen Pflichten im In- und Ausland nachzukommen. Wenn Sie für einen solchen Kunden oder Geschäftspartner tätig sind, können Sie in dieser Funktion mit Ihren Personendaten natürlich ebenfalls davon betroffen sein.
Darüber hinaus bearbeiten wir Personendaten von Ihnen und weiteren Personen, soweit erlaubt und es uns als angezeigt erscheint, auch für folgende Zwecke, an denen wir (und zuweilen auch Dritte) ein dem Zweck entsprechendes berechtigtes Interesse haben: 
Angebot und Weiterentwicklung unserer Angebote, Dienstleistungen und Websites, und weiteren Plattformen, auf welchen wir präsent sind;
Kommunikation mit Dritten und Bearbeitung derer Anfragen (zum Beispiel Bewerbungen, Medien-anfragen);
Prüfung und Optimierung von Verfahren zur Bedarfsanalyse zwecks direkter Kundenansprache sowie Erhebung von Personendaten aus öffentlich zugänglichen Quellen zwecks Kundenakquisition;
Werbung und Marketing (einschliesslich Durchführung von Anlässen), soweit Sie der Nutzung Ihrer Daten nicht widersprochen haben (wenn wir Ihnen als bestehender Kunde von uns Werbung zukommen lassen, können Sie dem jederzeit widersprechen, wir setzen Sie dann auf eine Sperrliste gegen weitere Werbesendungen);
Geltendmachung rechtlicher Ansprüche und Verteidigung in Zusammenhang mit rechtlichen Streitigkeiten und behördlichen Verfahren;
Verhinderung und Aufklärung von Straftaten und sonstigem Fehlverhalten (zum Beispiel) Durchführung interner Untersuchungen, Datenanalysen zur Betrugsbekämpfung);
Gewährleistungen unseres Betriebs, insbesondere der IT, unserer Websites, Apps und weiteren Plattformen;
Kauf und Verkauf von Geschäftsbereichen, Gesellschaften oder Teilen von Gesellschaften und andere gesellschaftsrechtliche Transaktionen und damit verbunden die Übertragung von Personendaten sowie Massnahmen zur Geschäftssteuerung und soweit zur Einhaltung gesetzlicher und regulatorischer Verpflichtungen sowie interner Vorschriften von eurecap GmbH.
Soweit Sie uns eine Einwilligung zur Bearbeitung Ihrer Personaldaten für bestimmte Zwecke erteilt haben (zum Beispiel bei Ihrer Anmeldung zum Erhalt von Newslettern oder Durchführung eines Background-Checks), bearbeiten wir Ihre Personendaten im Rahmen und gestützt auf diese Einwilligung, soweit wir keine andere Rechtsgrundlage haben und wir eine solche benötigen. Eine erteilte Einwilligung kann jederzeit widerrufen werden, was jedoch keine Aus-wirkung auf bereits erfolgte Datenbearbeitungen hat.
"
      data-en="We use the personal data we collect primarily to enter into and perform our agreements with our customers and business partners, so in particular in the context of the following activity:
the consultancy and project management in the field of customer marketing.
As part of this activity, we collect personal data for transactions with our customers and the purchase of products and services from our suppliers and subcontractors, as well as to comply with our legal obligations at home and abroad. If you work for such a customer or business partner, your personal data may of course also be concerned in this capacity.
We also process your and other person's data, to the extent permitted and as we deem appropriate, for the following purposes, in which we (and sometimes also third parties) have a legitimate interest: 
Offering and further developing our products, services and websites, apps, and other platforms on which we are present;
Communicating with third parties and processing their inquiries (for example, job applications, media inquiries);
Testing and optimizing procedures for analyzing needs to address customers directly, as well as collecting personal data from publicly available sources for customer acquisition;
Advertising and marketing (including the organization of events), unless you have objected to the use of your data (if we send you advertisements as an existing customer of ours, you can object to this at any time; we will then place you on a blocking list against further advertising mailings);
Assertion of legal claims and defense in connection with legal disputes and official proceedings;
Prevention and investigation of criminal offenses and other misconduct (for example, conducting internal investigations, data analysis to combat fraud);
Ensuring that our operations, in particular IT, our websites, apps, and other platforms, are running properly;
Purchase and sale of business units, companies, or parts of companies and other transactions under company law and the associated transfer of personal data, as well as measures for business management and, to the extent necessary to comply with legal and regulatory obligations and our internal regulations.
"
    >
      Nous utilisons les données personnelles que nous collectons principalement
      pour conclure et exécuter nos engagements avec nos clients et partenaires,
      en particulier dans le cadre de l'activité suivante : le conseil et le
      management de projet dans le domaine du marketing client. Dans le cadre de
      cette activité, nous collectons des données personnelles pour les
      transactions avec nos clients et l'achat de produits et services auprès de
      nos partenaires et sous-traitants, ainsi que pour nous conformer à nos
      obligations légales en Suisse et à l'étranger. Si vous travaillez pour un
      tel client ou partenaire, vos données à caractère personnel peuvent bien
      entendu également être concernées en cette qualité. Nous traitons
      également vos données et celles d'autres personnes, dans la mesure où cela
      est autorisé et où nous le jugeons approprié, dans les buts suivants,
      auxquels nous (et parfois aussi des tiers) avons un intérêt légitime : 
      Offrir et développer nos produits, services et sites web, applications et
      autres plateformes sur lesquelles nous sommes présents ; Communiquer avec
      des tiers et traiter leurs demandes (par exemple, les candidatures à un
      emploi, les demandes des médias) ; Tester et optimiser les procédures pour
      analyser les besoins de nos clients, contacter nos clients, et des données
      personnelles à partir de sources accessibles au public pour l'acquisition
      de clients ; Publicité et marketing (y compris l'organisation
      d'événements), à moins que vous n'ayez refusé l'utilisation de vos données
      (si nous vous envoyons des publicités en tant que client existant, vous
      pouvez vous y opposer à tout moment ; nous vous inscrirons alors sur une
      liste bloquant les envois publicitaires ultérieurs) ; Faire valoir nos
      droits et nous défendre dans le cadre de litiges et de procédures
      administratives ou judiciaires ; Prévention et enquête en matière
      d'infractions pénales et d'autres comportements répréhensibles (par
      exemple, conduite d'enquêtes internes, analyse de données pour lutter
      contre la fraude) ; Veiller au bon fonctionnement de nos opérations, en
      particulier de l'informatique, de nos sites web, de nos apps et d'autres
      plateformes ; L'achat et la vente de départements, d'entreprises ou de
      parties d'entreprises et d'autres transactions relevant du droit des
      sociétés ainsi que le transfert correspondant de données personnelles, de
      même que les mesures de gestion des affaires et, dans la mesure
      nécessaire, le respect des obligations légales et réglementaires et de nos
      règlements internes.
    </p>
    <h4
      class="to-translate"
      data-de="
    Cookies/Tracking und andere Technologien im Zusammenhang mit der Nutzung unserer Website"
      data-en="Cookies / Tracking and other techniques regarding the use of our website 
    "
    >
      Cookies / Traçage et autres techniques concernant l'utilisation de notre
      site web 
    </h4>
    <p
      class="to-translate"
      data-en='We typically use "cookies" and similar techniques on our website(s), which allow for the identification of your browser or device. A cookie is a small text file that is sent to your computer and automatically saved by the web browser on your computer or mobile device when you visit our website(s). If you revisit our website, we may recognize you, even if we do not know your identity. Besides cookies that are only used during a session and deleted after you visit the website ("session cookies"), we may use cookies to save user configurations and other information for a certain period (for example, two years) ("permanent cookies"). Notwithstanding the foregoing, you may configure your browser settings in a way that rejects cookies, only saves them for one session, or deletes them prematurely. Most browsers are preset to accept cookies. We use permanent cookies to save user configuration (for example, language, automated log-in), to understand how you use our services and content, and to show you customized offers and advertisements (which may also happen on websites of other companies; should your identity be known to us, such companies will not learn your identity from us; they will only know that the same user is visiting their website has previously visited a certain website). Certain cookies are sent to you from us, others from business partners with whom we collaborate. If you block cookies, certain functions (for example, language settings, shopping basket, and ordering processes) may be no longer available to you.
      Under applicable law, we may include visible and invisible image files in our newsletters and other marketing e-mails. If such image files are retrieved from our servers, we can determine whether and when you have opened the e-mail, so that we can measure and better understand how you use our offers and customize them. You may disable this in your e-mail program, which will usually be a default setting.
      By using our website(s) and consenting to the receipt of newsletters and other marketing e-mails you agree to our use of such techniques. If you object, you must configure your browser or e-mail program accordingly.
      We may use Google Analytics or similar services on our website. These are services provided by third parties, which may be located in any country worldwide (in the case of Google Analytics Google Ireland Ltd. (located in Ireland), Google Ireland relies on Google LLC (located in the United States) as its sub-processor (both “Google”, www.google.com) and which allow us to measure and evaluate the use of our website (on an anonymized basis). For this purpose, permanent cookies are used, which are set by the service provider. We have configured the service so that the IP addresses of visitors are truncated by Google in Europe before forwarding them to the United States and then cannot be traced back. We have turned off the «Data sharing» option and the “Signals” option. Although we can assume that the information we share with Google is not personal data for Google, it may be possible that Google may be able to conclude the identity of visitors based on the data collected, create personal profiles and link this data with the Google accounts of these individuals for its purposes. If you have registered with the service provider, the service provider will also know your identity. In this case, the processing of your data by the service provider will be conducted under its data protection regulations. The service provider only provides us with data on the use of the respective website (but not any personal information of you).
      In addition, we use plug-ins from social networks such as Facebook, Twitter, YouTube, Pinterest, or Instagram on our websites. This is visible for you (typically based on the respective symbols). We have configured these elements to be disabled by default. If you activate them (by clicking on them), the operators of the respective social networks may record that you are on our website and where on our website you are exactly and may use this information for their purposes. This processing of your data lies in the responsibility of the respective operator and occurs according to its data protection regulations. We do not receive any information about you from the respective operator.
      '
      data-de='Wir setzen auf unseren Websites typischerweise "Cookies" und vergleichbare Techniken ein, mit denen Ihr Browser oder Ihr Gerät identifiziert werden kann. Ein Cookie ist eine kleine Datei, die an Ihren Computer gesendet bzw. vom verwendeten Webbrowser automatisch auf Ihrem Computer oder mobilen Gerät gespeichert wird, wenn Sie unsere Website besuchen. Wenn Sie diese Website erneut aufrufen , können wir Sie so wiedererkennen, selbst wenn wir nicht wissen, wer Sie sind. Neben Cookies, die lediglich während einer Sitzung benutzt und nach Ihrem Website-Besuch gelöscht werden ("Session Cookies"), können Cookies auch benutzt werden, um Nutzereinstellungen und andere Informationen über eine bestimmte Zeit (zum Beispiel zwei Jahre) zu speichern ("permanente Cookies"). Sie können Ihren Browser jedoch so einstellen, dass er Cookies zurückweist, nur für eine Sitzung speichert oder sonst vorzeitig löscht. Die meisten Browser sind so voreingestellt, dass Sie Cookies akzeptieren. Wir nutzen permanente Cookies damit Sie Benutzereinstellungen speichern (zum Beispiel Sprache, Autologin), damit wir besser verstehen, wie Sie unsere Angebote und Inhalte nutzen, und damit wir Ihnen auf Sie zugeschnittene Angebote und Werbung anzeigen können (was auch auf Websites von anderen Firmen geschehen kann; diese erfahren dann allerdings von uns nicht, wer Sie sind, falls wir selbst das überhaupt wissen, denn sie sehen nur, dass auf ihrer Website derselbe Nutzer ist, der auch bei uns auf einer bestimmten Seite war). Gewisse der Cookies werden von uns gesetzt, gewisse auch von Vertragspartnern, mit denen wir zusammenarbeiten. 
    Wenn Sie Cookies sperren, kann es sein, dass gewisse Funktionalitäten (wie zum Beispiel Sprachwahl, Warenkorb, Bestellprozesse) nicht mehr funktionieren. 
    Wir bauen in unseren Newslettern und sonstigen Marketing-E-Mails teilweise und soweit erlaubt auch sicht- und unsichtbare Bildelemente ein, durch deren Abruf von unseren Servern wir feststellen können, ob und wann Sie die E-Mail geöffnet haben, damit wir auch hier messen und besser verstehen können, wie Sie unsere Angebote nutzen und sie auf Sie zuschneiden können. Sie können dies in Ihrem E-Mail-Programm blockieren; die meisten sind so voreingestellt, dass Sie dies tun.
    Mit der Nutzung unserer Websites und der Einwilligung in den Erhalt von Newslettern und anderen Marketing-E-Mails stimmen Sie dem Einsatz dieser Techniken zu. Wollen Sie dies nicht, dann müssen Sie Ihren Browser bzw. Ihre E-Mail-Programm entsprechend einstellen.
    Wir setzen auf unseren Websites mitunter Google Analytics oder vergleichbare Dienste ein. Dies ist eine Dienstleistung von Dritten, die sich in irgendeinem Land der Erde befinden können (im Falle von Google Analytics ist es Google Irland (mit Sitz in Irland), Google Irland stützt sich dabei auf Google LLC (mit Sitz in den USA) als Auftragsbearbeiter (beide "Google", www.google.com), mit welcher wir die Nutzung der Website (nicht personenbezogen) messen und auswerten können. Hierzu werden ebenfalls permanente Cookies benutzt, die der Dienstleister setzt. Wir haben den Dienst so konfiguriert, dass die IP-Adressen der Besucher von Google in Europa vor einer Weiterleitung in die USA gekürzt werden und dadurch nicht zurückverfolgt werden können. Wir haben die Einstellungen "Datenweitergabe" und "Signals" ausgeschaltet. Obwohl wir annehmen können, dass die Informationen, welche wir mit Google teilen, für Google keine Personendaten sind, ist es möglich, dass Google von diesen Daten für eigene Zwecke Rückschlüsse auf die Identität der Besucher ziehen, personenbezogene Profile erstellen und diese Daten mit den Google-Konten dieser Personen verknüpfen kann. Soweit Sie sich beim Dienstleister selbst registriert haben, kennt der Dienstleister Sie auch. Die Bearbeitung Ihrer Personendaten durch den Dienstleister erfolgt dann in Verantwortung des Dienstleisters nach dessen Datenschutzbestimmungen. Uns teilt der Dienstleister lediglich mit, wie unsere jeweilige Website genutzt wird (keine Angaben über Sie persönlich).
    Wir setzen auf unseren Websites ferner sog. Plug-Ins von sozialen Netzwerken wie Facebook, Twitter, Youtube, Pinterest, Xing, LinkedIn oder Instagram. Das ist für Sie jeweils ersichtlich (typischerweise über entsprechende Symbole). Wir haben diese Elemente so konfiguriert, dass sie standardmässig deaktiviert sind. Aktivieren Sie sie (durch Anklicken), können die Betreiber der jeweiligen sozialen Netzwerke registrieren, dass Sie auf unserer Website sind und wo und können diese Informationen für ihre Zwecke nutzen. Die Bearbeitung Ihrer Personendaten erfolgt dann in Verantwortung dieses Betreibers nach dessen Datenschutzbestimmungen. Wir erhalten von ihm keine Angaben zu Ihnen.
    '
    >
      Nous utilisons généralement des "cookies" et des techniques similaires sur
      notre (nos) site(s) web, qui permettent d'identifier votre navigateur ou
      votre appareil. Un cookie est un petit fichier texte envoyé à votre
      ordinateur et automatiquement enregistré par le navigateur web de votre
      ordinateur ou de votre appareil mobile lorsque vous visitez notre (nos)
      site(s) web. Lors de la prochaine visite de notre (nos) site(s), nous
      pouvons vous reconnaître, même si nous ne savons pas qui vous êtes. Outre
      les cookies qui ne sont utilisés que pendant une session et qui sont
      supprimés après votre visite sur le site web ("cookies de session"), nous
      pouvons utiliser des cookies pour enregistrer les configurations de
      l'utilisateur et d'autres informations pendant une certaine période (par
      exemple, deux ans) ("cookies permanents"). Nonobstant ce qui précède, vous
      pouvez configurer les paramètres de votre navigateur de manière à rejeter
      les cookies, à ne les enregistrer que pour une session ou à les supprimer
      prématurément. La plupart des navigateurs sont préréglés pour accepter les
      cookies. Nous utilisons des cookies permanents pour enregistrer la
      configuration de l'utilisateur (par exemple, la langue, la connexion
      automatisée), pour comprendre comment vous utilisez nos services et notre
      contenu, et pour vous présenter des offres et des publicités
      personnalisées (ce qui peut également se produire sur les sites web
      d'autres entreprises ; si nous connaissons votre identité, ces entreprises
      ne l'apprendront pas de notre part ; elles sauront seulement que le même
      utilisateur qui visite leur site web a déjà visité un certain site web).
      Certains cookies vous sont envoyés par nous, d'autres par des partenaires
      commerciaux avec lesquels nous collaborons. Si vous bloquez les cookies,
      il se peut que certaines fonctions (telles que, par exemple, les
      paramètres linguistiques, le panier d'achat et les processus de commande)
      ne soient plus disponibles pour vous. Dans le cadre de la législation
      applicable, nous pouvons inclure des fichiers images visibles et
      invisibles dans nos newsletters et autres emails de marketing. Si ces
      fichiers images sont récupérés sur nos serveurs, nous pouvons déterminer
      si et quand vous avez ouvert l'email, ce qui nous permet de mesurer et de
      mieux comprendre comment vous utilisez nos offres et de les personnaliser.
      Vous pouvez désactiver cette fonction dans votre messagerie électronique ;
      il s'agit généralement d'un paramètre par défaut. En utilisant notre (nos)
      site(s) web et en acceptant de recevoir des newsletters et d'autres emails
      marketing, vous consentez à ce que nous utilisions ces techniques. Si vous
      vous y opposez, vous devez configurer votre navigateur ou votre messagerie
      en conséquence. Nous pouvons utiliser Google Analytics ou des services
      similaires sur notre (nos) site(s) web. Il s'agit de services fournis par
      des tiers, qui peuvent être situés dans n'importe quel pays du monde (dans
      le cas de Google Analytics Google Ireland Ltd. (situé en Irlande), Google
      Ireland s'appuie sur Google LLC (situé aux États-Unis) en tant que
      sous-traitant (tous deux "Google", www.google.com) et qui nous permettent
      de mesurer et d'évaluer l'utilisation de notre site web (sur une base
      anonyme). À cette fin, des cookies permanents sont utilisés, qui sont mis
      en place par le prestataire de services. Nous avons configuré le service
      de manière à ce que les adresses IP des visiteurs soient tronquées par
      Google en Europe avant d'être transmises aux États-Unis et qu'il soit
      ensuite impossible de les retracer. Nous avons désactivé l'option "partage
      des données" et l'option "signals". Bien que nous partions du principe que
      les informations que nous partageons avec Google ne constituent pas des
      données personnelles pour Google, il est possible que Google soit en
      mesure de déterminer l'identité des visiteurs sur la base des données
      collectées, de créer des profils personnels et de relier ces données aux
      comptes Google de ces personnes pour ses propres besoins. Si vous vous
      êtes inscrit auprès du prestataire de services, ce dernier connaîtra
      également votre identité. Dans ce cas, le traitement de vos données par le
      prestataire de services s'effectue dans le cadre de ses règles de
      protection des données. Le prestataire de services nous fournit uniquement
      des données sur l'utilisation du site web concerné (mais pas
      d'informations personnelles vous concernant). En outre, nous utilisons des
      plug-ins de réseaux sociaux tels que Facebook, Twitter, YouTube, Pinterest
      ou Instagram sur nos sites web. Vous pouvez voir ces éléments (
      habituellement grâce aux logos correspondants). Nous avons configuré ces
      éléments pour qu'ils soient désactivés par défaut. Si vous les activez (en
      cliquant dessus), les opérateurs des réseaux sociaux respectifs peuvent
      enregistrer que vous êtes sur notre site web et à quel endroit de notre
      site web vous vous trouvez exactement et peuvent utiliser ces informations
      à leurs fins. Ce traitement de vos données relève de la responsabilité de
      l'opérateur concerné et s'effectue conformément à ses dispositions en
      matière de protection des données. Nous ne recevons aucune information
      vous concernant de la part de l'opérateur concerné.
    </p>
    <h4
      class="to-translate"
      data-en="Who will my Data be shared with?
    "
      data-de="An wen werden meine Daten weitergegeben?
    "
    >
      Avec qui mes données sont-elles partagées ?
    </h4>
    <p
      class="to-translate"
      data-de="Wir geben im Rahmen unserer geschäftlichen Aktivitäten und der Zwecke
    gemäss Ziff. 3, soweit erlaubt und es uns als angezeigt erscheint, auch
    Dritten bekannt, sei es, weil sie diese für uns bearbeiten, sei es, weil
    sie sie für ihre eigenen Zwecke verwenden wollen. Dabei geht es
    insbesondere um folgende Stellen: Dienstleister von uns (wie zum Beispiel
    Banken, Versicherungen), einschliesslich Auftragsbearbeitern (wie zum
    Beispiel IT-Provider); Händler, Lieferanten, Subunternehmer und sonstige
    Geschäftspartner; Kunden; in- und ausländische Behörden, Amtsstellen oder
    Gerichten; Medien; Öffentlichkeit, einschliesslich Besuchern von Websites
    und sozialer Medien; Mitbewerber, Branchenorganisationen, Verbände,
    Organisationen und weitere Gremien; Erwerber oder Interessenten am Erwerb
    von Geschäftsbereichen; anderen Parteien in möglichen oder tatsächlichen
    Rechtsverfahren; alle gemeinsam Empfänger. Sofern wir Daten an Dritte
    weitergeben, beachten wir die gesetzlichen Vorgaben und schliessen
    insbesondere, zwecks Schutz Ihrer personenbezogenen Daten,
    Auftragsverarbeitungsverträge oder ähnliche Vereinbarungen mit den
    jeweiligen Empfängern ab."
      data-en='In the context of our business activities and in line with the purposes of the data processing set out in Section 3, we may transfer data to third parties, insofar as such a transfer is permitted and we deem it appropriate, for them to process data for us or, as the case may be their purposes. In particular, the following categories of recipients may be concerned: 
    our service providers (such as banks or insurance, for instance), including processors (such as IT providers);
    dealers, suppliers, subcontractors, and other business partners;
    clients;
    domestic and foreign authorities or courts;
    the media;
    the public, including users of our websites and social media;
    competitors, industry organizations, associations, organizations, and other bodies;
    acquirers or parties interested in the acquisition of business units;
    other parties in potential or pending legal proceedings;
    together "Recipients".
    If we pass on data to third parties, we comply with the relevant legal requirements and, in particular, conclude data processing agreements or similar agreements with the respective Recipients to protect your data.
    '
    >
      Dans le cadre de nos activités commerciales et conformément aux buts
      décrits au point 3, nous pouvons transférer des données à des tiers, dans
      la mesure où un tel transfert est autorisé et où nous le jugeons
      approprié, afin qu'ils traitent les données pour nous ou, le cas échéant,
      pour leurs propres besoins. En particulier, les catégories de
      destinataires suivantes peuvent être concernées:  nos prestataires de
      services (tels que les banques ou les assurances, par exemple), y compris
      les sous-traitants (tels que les prestataires de services informatiques);
      les concessionnaires, fournisseurs, sous-traitants et autres partenaires
      commerciaux; les clients; les autorités ou tribunaux nationaux et
      étrangers; les médias; le public, y compris les utilisateurs de notre
      (nos) site(s) web et des médias sociaux ; les concurrents, les
      organisations sectorielles, les associations, les fédérations et autres
      organismes; les acquéreurs ou les parties intéressées par l'acquisition de
      secteurs d'activité; d'autres parties dans le cadre de procédures
      judiciaires potentielles ou en cours ; ensemble, les "destinataires". Si
      nous transmettons des données à des destinataires, nous respectons les
      exigences légales pertinentes et, en particulier, nous concluons des
      contrats de traitement des données ou des accords similaires avec les
      destinataires respectifs afin de protéger vos données.  
    </p>
    <h4
      class="to-translate"
      data-en="Will my data be transferred abroad?
    "
      data-de="Werden meine Daten ins Ausland übermittelt?"
    >
      Est-ce que mes données sont transférées à l’étranger?
    </h4>
    <p
      class="to-translate"
      data-en="We may share data with persons, authorities, organizations, companies, or other entities abroad. In particular, we may transfer personal data to all countries in which our service providers process personal data.
    If a Recipient is located in a country without adequate statutory data protection, we require the Recipient contractually to comply with the applicable data protection (for this purpose, we use the revised standard contractual clauses of the European Commission, which are available here: https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?), unless the Recipient is already subject to a legally recognized set of rules to ensure data protection and we cannot rely on an exemption provision. An exception may apply in particular in the case of legal proceedings abroad, but also in cases of overriding public interests or if the performance of a contract requires such disclosure, if you have consented to it, or if it is a matter of data made generally available by you, the processing of which you have not objected to. 
     "
      data-de="Es kann sein, dass wir Daten an Personen, Behörden, Organisationen, Unternehmen oder anderen Stellen im Ausland bekanntgeben. Wir können Personendaten insbesondere in alle Länder, in denen unsere Auftragsbearbeiter Personendaten bearbeiten.
    Befindet sich ein Empfänger in einem Land ohne angemessenen gesetzlichen Datenschutz, verpflichten wir den Empfänger vertraglich zur Einhaltung des anwendbaren Datenschutzes (dazu verwenden wir die revidierten Standardvertragsklauseln der Europäischen Kommission, die hier: https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj? abrufbar sind), soweit er nicht bereits einem gesetzlich anerkannten Regelwerk zur Sicherstellung des Datenschutzes unterliegt und wir uns nicht auf eine Ausnahmebestimmung stützen können. Eine Ausnahme kann namentlich bei Rechtsverfahren im Ausland gelten, aber auch in Fällen überwiegender öffentlicher Interessen oder wenn eine Vertragsabwicklung eine solche Bekanntgabe erfordert, wenn Sie eingewilligt haben oder wenn es sich um von Ihnen allgemein zugänglich gemachte Daten handelt, deren Bearbeitung Sie nicht widersprochen haben.
    "
    >
      Nous pouvons partager des données avec des destinataires, des personnes,
      des autorités, des organisations, des entreprises ou d'autres entités à
      l'étranger. En particulier, nous pouvons transférer des données
      personnelles vers tous les paysdans lesquels nos prestataires de services
      traitent des données personnelles. Si un destinataire est situé dans un
      pays ne disposant pas d'une protection légale adéquate des données, nous
      demandons au destinataire de se conformer contractuellement à la
      protection des données applicable (à cette fin, nous utilisons les clauses
      contractuelles types révisées de la Commission européenne, qui sont
      disponibles ici: https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?), à
      moins que le destinataire ne soit déjà soumis à un ensemble de règles
      légalement reconnues pour garantir la protection des données et que nous
      ne puissions pas nous appuyer sur une disposition d'exemption. Une
      exemption peut s'appliquer notamment en cas de procédure judiciaire à
      l'étranger, mais aussi en cas d'intérêts publics prépondérants ou si
      l'exécution d'un contrat exige un tel transfert, si vous y avez consenti,
      ou s'il s'agit de données que vous avez mises à disposition de manière
      générale et au traitement desquelles vous ne vous êtes pas opposé.
    </p>
    <h4
      class="to-translate"
      data-en="How long will my data be retained?
    "
      data-de="Wie lange werden meine Daten aufbewahrt?
    "
    >
      Combien de temps mes données sont-elles conservées ?
    </h4>
    <p
      class="to-translate"
      data-en="We process and retain your data for as long as it is necessary for the fulfillment of our contractual and legal obligations or otherwise the purposes pursued with the processing, i.e., for example, for the duration of the entire business relationship (from the initiation, processing to the termination of an agreement) and beyond that under the statutory retention and documentation obligations. In this context, we may retain personal data for the period during which claims may be asserted against our company and to the extent that we are otherwise required to do so by law or legitimate business interests so require (for example, for evidence and documentation purposes). As soon as your data is no longer required for the above-mentioned purposes, it will be deleted or anonymized as a matter of principle and to the extent possible. For operational data (for example, system records, and logs), shorter retention periods of twelve months or less generally apply.
     "
      data-de="Wir verarbeiten und speichern Ihre Personendaten, solange es für die Erfüllung unserer vertraglichen und gesetzlichen Pflichten oder sonst die mit der Bearbeitung verfolgten Zwecke erforderlich ist, d.h. also zum Beispiel für die Dauer der gesamten Geschäftsbeziehung (von der Anbahnung, Abwicklung bis zur Beendigung eines Vertrags) sowie darüber hinaus gemäss den gesetzlichen Aufbewahrungs- und Dokumentationspflichten. Dabei ist es möglich, dass Personendaten für die Zeit aufbewahrt werden, in der Ansprüche gegen unser Unternehmen geltend gemacht werden können und soweit wir anderweitig gesetzlich dazu verpflichtet sind oder berechtigte Geschäftsinteressen dies erfordern (zum Beispiel für Beweis- und Dokumentationszwecke). Sobald Ihre Personendaten für die oben genannten Zwecke nicht mehr erforderlich sind, werden sie grundsätzlich und soweit möglich gelöscht oder anonymisiert. Für betriebliche Daten (zum Beispiel Systemprotokolle, Logs), gelten grundsätzliche kürzere Aufbewahrungsfristen von zwölf Monaten oder weniger. 
     "
    >
      Nous traitons et conservons vos données aussi longtemps que cela est
      nécessaire à l'exécution de nos obligations contractuelles et légales ou
      aux buts poursuivis par le traitement, c'est-à-dire, par exemple, pendant
      toute la durée de la relation commerciale (de la conclusion à la
      résiliation d'un contrat) et au-delà en vertu des obligations légales de
      conservation et de documentation. Dans ce contexte, nous pouvons conserver
      des données à caractère personnel pendant la période au cours de laquelle
      des réclamations peuvent être formulées à l'encontre de notre société et
      dans la mesure où la loi nous y oblige ou que des intérêts commerciaux
      légitimes l'exigent (par exemple, à des fins de preuve et de
      documentation). Dès que vos données ne sont plus nécessaires aux buts
      susmentionnés, elles sont par principe et dans la mesure du possible
      effacées ou anonymisées. Pour les données opérationnelles (par exemple,
      les relevés de système et les registres), il est en général prévu des
      délais de conservation plus courts, de douze mois ou moins.  
    </p>
    <h4
      class="to-translate"
      data-en="How do we protect your data?
    "
      data-de="Wie schützen wir Ihre Daten?"
    >
      Comment protégeons-nous vos données ?
    </h4>
    <p
      class="to-translate"
      data-en="We take appropriate technical and organizational security measures to protect your data from unauthorized access and misuse. 
    We already take the protection of personal data into account during the design or choice of hardware, software, or processes using appropriate technical and organizational measures. Furthermore, we ensure data protection-friendly default settings.
    "
      data-de="Wir treffen angemessene technische und organisatorische Sicherheitsvorkehrungen zum Schutz Ihrer Personendaten vor unberechtigtem Zugriff und Missbrauch. 
    Den Schutz personenbezogener Daten berücksichtigen wir bereits bei Entwicklung bzw. Auswahl von Hardware, Software oder Verfahren durch entsprechende technische und organisatorische Massnahmen. Ferner stellen wir eine datenschutzfreundliche Voreinstellung sicher.
    "
    >
      Nous prenons des mesures de sécurité techniques et organisationnelles
      appropriées pour protéger vos données contre tout accès non autorisé et
      toute utilisation abusive.  Nous tenons déjà compte de la protection des
      données personnelles lors de la conception ou du choix du hardware, du
      software ou des processus en utilisant des mesures techniques et
      organisationnelles appropriées. En outre, nous veillons à ce que les
      paramètres par défaut soient favorables à la protection des données.
    </p>
    <h4
      class="to-translate"
      data-en="Am I obliged to provide my data?
      "
      data-de="Bin ich dazu verpflichtet meine Daten preiszugeben?
    "
    >
      Ai-je l’obligation de fournir mes données ?
    </h4>
    <p
      class="to-translate"
      data-en="In the course of our business relationship, you must provide those personal data that are necessary for the start and performance of a business relationship and the fulfillment of the associated contractual obligations (you generally do not have a statutory obligation to provide us with data). Without this data, we will generally not be able to enter into or perform an agreement with you (or the entity or person you represent). Also, our websites cannot be used if certain traffic-securing information (such as IP address) is not disclosed.
    "
      data-de="Im Rahmen unserer Geschäftsbeziehung müssen Sie diejenigen Personendaten bereitstellen, die für die Aufnahme und Durchführung einer Geschäftsbeziehung und der Erfüllung der damit verbundenen vertraglichen Pflichten erforderlich sind (eine gesetzliche Pflicht, uns Daten bereitzustellen, haben Sie in der Regel nicht). Ohne diese Daten werden wir in der Regel nicht in der Lage sein, einen Vertrag mit Ihnen (oder der Stelle oder Person, die Sie vertreten) zu schliessen oder diesen abzuwickeln. Auch die Website kann nicht genutzt werden, wenn gewisse Angaben zur Sicherstellung des Datenverkehrs (wie zum Beispiel IP-Adresse) nicht offengelegt wird.
     "
    >
      Dans le cadre de notre relation commerciale, vous devez fournir les
      données personnelles nécessaires à sa mise en place et à son exécution,
      ainsi qu'au respect des obligations contractuelles qui y sont liées (vous
      n'avez généralement pas d'obligation légale de nous fournir des données).
      Sans ces données, nous ne serons généralement pas en mesure de conclure ou
      d'exécuter un quelconque contrat avec vous (ou avec l'entité ou la
      personne que vous représentez). En outre, notre (nos) site(s) web ne
      peuvent pas être utilisés si certaines informations relatives à la
      sécurité du trafic (telles que l'adresse IP) ne sont pas divulguées.
    </p>
    <h4
      class="to-translate"
      data-en="Which rights do I have?
    "
      data-de="Welche Datenschutzrechte habe ich?"
    >
      Quels sont mes droits?
    </h4>
    <p
      class="to-translate"
      data-en="Following and as far as provided by applicable law (as is the case where the GDPR is applicable), you have the following rights:
    the right to request information from us as to whether and what data we process about you;
    the right to have us correct data if it is inaccurate;
    the right to request that we delete data;
    the right to request that we provide certain personal data in a commonly used electronic format or transfer it to another controller;
    the right to withdraw consent, insofar as our processing is based on your consent;
    the right to obtain, on request, further information necessary for the exercise of these rights.
    Please note, however, that we reserve the right to assert the restrictions provided for by law on our part, for example, if we are required to retain or process certain data, have an overriding interest in doing so (to the extent that we are entitled to rely on this) or if we need it for the assertion of claims. If you incur costs, we will inform you in advance. We have already informed you about the possibility of withdrawing your consent in Section 3. Please note that exercising these rights may conflict with contractual arrangements and may have consequences such as early termination of the agreement or costs. We will inform you in advance if this is not already specified in the agreement. 
    The exercise of such rights usually requires that you prove your identity (for example, using a copy of your identity card, where your identity is otherwise not clear or cannot be verified). To exercise your rights, you may contact us at the address provided in Section 1.
    Every data subject also has the right to enforce their claims in court or to file a complaint with the competent data protection authority.  The competent data protection authority in Switzerland is the Federal Data Protection and Information Commissioner (http://www.edoeb.admin.ch).
    "
      data-de="Sie haben im Rahmen des auf Sie anwendbaren Datenschutzrechts und soweit darin vorgesehen (wie etwa im Falle der DSGVO) folgende Rechte:
    das Recht, von uns Auskunft zu verlangen, ob und welche Daten wir von Ihnen bearbeiten;
    das Recht, dass wir Daten korrigieren, wenn sie unrichtig sind;
    das Recht, die Löschung von Daten zu verlangen;
    das Recht, von uns die Herausgabe bestimmter Personendaten in einem gängigen elektronischen Format oder ihre Übertragung an einen anderen Verantwortlichen zu verlangen;
    das Recht, eine Einwilligung zu widerrufen, soweit unsere Bearbeitung auf Ihrer Einwilligung beruht;
    das Recht auf Nachfrage weitere Informationen zu erhalten, die für die Ausübung dieser Rechte erforderlich sind.
    Bitte beachten Sie aber, dass wir uns vorbehalten, unsererseits die gesetzlich vorgesehenen Einschränkungen geltend zu machen, etwa wenn wir zur Aufbewahrung oder Bearbeitung gewisser Daten verpflichtet sind, daran ein überwiegendes Interesse haben (soweit wir uns darauf berufen dürfen) oder sie für die Geltendmachung von Ansprüchen benötigen. Falls für Sie Kosten anfallen, werden wir Sie vorab informieren. Über die Möglichkeit, Ihre Einwilligung zu widerrufen, haben wir bereits in Ziff. 3 informiert. Beachten Sie, dass die Ausübung dieser Rechte im Konflikt zu vertraglichen Abmachungen stehen kann und dies Folgen wie zum Beispiel die vorzeitige Vertragsauflösung oder Kostenfolgen haben kann. Wir werden Sie diesfalls vorgängig informieren, wo dies nicht bereits vertraglich geregelt ist. 
    Die Ausübung solcher Rechte setzt in der Regel voraus, dass Sie Ihre Identität eindeutig nachweisen (zum Beispiel durch eine Ausweiskopie, wo Ihre Identität sonst nicht klar ist bzw. verifiziert werden kann). Zur Geltendmachung Ihrer Rechte können Sie uns unter der in Ziffer 1 angegebenen Adresse kontaktieren.
    Jede betroffene Person hat überdies das Recht, ihre Ansprüche gerichtlich durchzusetzen oder bei der zuständigen Datenschutzbehörde eine Beschwerde einzureichen.  Die zuständige Datenschutzbehörde der Schweiz ist der Eidgenössische Datenschutz- und Öffentlichkeitsbeauftragte (http://www.edoeb.admin.ch).
    "
    >
      Dans la mesure où le droit applicable le prévoit (notamment lorsque le
      RGPD s'applique), vous disposez des droits suivants : le droit de nous
      demander des informations sur l'existence et la nature des données que
      nous traitons à votre sujet; le droit de nous demander de corriger les
      données si elles sont inexactes; le droit de nous demander d'effacer des
      données; le droit de nous demander de fournir certaines données à
      caractère personnel dans un format électronique couramment utilisé ou de
      les transférer à un autre responsable du traitement; le droit de retirer
      votre consentement, dans la mesure où nous traitons des données sur la
      base de votre consentement; le droit d'obtenir, sur demande, des
      informations supplémentaires nécessaires à l'exercice de ces droits.
      Veuillez toutefois noter que nous nous réservons le droit de faire valoir
      les restrictions prévues par la loi, par exemple si nous sommes tenus de
      conserver ou de traiter certaines données, si nous avons un intérêt
      supérieur à le faire (dans la mesure où nous pouvons nous en prévaloir) ou
      si nous en avons besoin pour faire valoir des droits. Si vous devez
      engager des frais, nous vous en informerons à l'avance. Nous vous avons
      déjà informé de la possibilité de retirer votre consentement au point 3.
      Veuillez noter que l'exercice de ces droits peut entrer en conflit avec
      les dispositions contractuelles et peut avoir des conséquences telles
      qu'une résiliation anticipée du contrat ou des frais. Nous vous en
      informerons à l'avance si cela n'est pas déjà spécifié dans la
      convention.  L'exercice de ces droits nécessite généralement que vous
      prouviez votre identité (par exemple, en utilisant une copie de votre
      carte d'identité, lorsque votre identité n'est pas claire ou ne peut pas
      être vérifiée). Pour exercer vos droits, vous pouvez nous contacter à
      l'adresse indiquée au point 1. Toute personne concernée a également le
      droit de faire valoir ses droits en justice ou de déposer une plainte
      auprès de l'autorité compétente en matière de protection des données. 
      L'autorité compétente en matière de protection des données en Suisse est
      le Préposé fédéral à la protection des données et à la transparence
      (http://www.edoeb.admin.ch).
    </p>
    <h4
      class="to-translate"
      data-de="Änderungen "
      data-en="Amendments to this Privacy Policy
    "
    >
      Modifications de cette déclaration de protection des données
    </h4>
    <p
      class="to-translate"
      data-de="Wir können diese Datenschutzerklärung jederzeit ohne Vorankündigung anpassen. Es gilt die jeweils aktuelle, auf unserer Website publizierte Fassung. Soweit die Datenschutzerklärung Teil einer Vereinbarung mit Ihnen ist, werden wir Sie im Falle einer Aktualisierung über die Änderung per E-Mail oder auf andere geeignete Weise informieren. "
      data-en="We may amend this Privacy Policy at any time without prior notice. The current version published on our website shall apply. If this Privacy Policy is part of an agreement with you, we will notify you by e-mail or other appropriate means in case of an amendment. "
    >
      Nous pouvons modifier la présente déclaration de protection des données à
      tout moment et sans préavis. La version actuelle publiée sur notre (nos)
      site(s) web fait foi. Si la présente déclaration protection des données
      fait partie d'un accord conclu avec vous, nous vous informerons par e-mail
      ou par tout autre moyen approprié en cas de modification.
    </p>
  </div>
</template>
