<template>
  <router-link @click='scrollToTop' :to="where" data-en="Find Out More" data-de="Mehr Infos" class="enSavoirPlus to-translate">En savoir plus</router-link>
</template>
<script setup>
import translate from '@/assets/js/translation'

const scrollToTop = () => {
  window.scrollTo(0, 0)
  setTimeout(() => {
    translate(document.querySelector('header img.active').dataset.flag)
  }, 10)
}
</script>
<script>
export default {
  name: 'EnSavoirPlus',
  props: {
    where: String
  }
}
</script>
