<template>
  <main class="contact">
    <section class="contact-left">
      <div class="contact-hero-banner-text">
        <div class="contact-title transition-x">
          <h2 class="to-translate contactUs" data-en="R" data-de="Kontaktier">
            Contact
          </h2>
          <svg
            v-if="showSVG"
            xmlns="http://www.w3.org/2000/svg"
            width="50.886"
            height="78.916"
            viewBox="0 0 50.886 78.916"
          >
            <g
              id="Groupe_98"
              data-name="Groupe 98"
              transform="translate(-445.332 -182.157)"
            >
              <path
                id="Tracé_383"
                data-name="Tracé 383"
                d="M190.7,76H149.255a16.017,16.017,0,0,0,5.933,10.844,19.321,19.321,0,0,0,12.58,4.095q9.154,0,14.746-6.171l5.1,5.978A22.492,22.492,0,0,1,179,96.914a32.519,32.519,0,0,1-25.874-1.2,23.71,23.71,0,0,1-9.687-9.3,26.686,26.686,0,0,1-3.426-13.5,26.943,26.943,0,0,1,3.324-13.4,23.745,23.745,0,0,1,9.154-9.3,26.133,26.133,0,0,1,13.158-3.324A25.574,25.574,0,0,1,178.7,50.214a23.351,23.351,0,0,1,8.961,9.3A28.293,28.293,0,0,1,190.9,73.207,27.577,27.577,0,0,1,190.7,76ZM154.518,58.744a16.314,16.314,0,0,0-5.252,10.606h32.771A16.414,16.414,0,0,0,176.785,58.8a17.24,17.24,0,0,0-22.256-.045Z"
                transform="translate(305.322 162.037)"
              />
              <path
                id="Tracé_384"
                data-name="Tracé 384"
                d="M163.085,23.29c4.685,0,2.756,13.748,0,19.522-2.756-5.774-4.673-19.522,0-19.522Z"
                transform="translate(308.042 158.867)"
              />
              <path
                id="Tracé_385"
                data-name="Tracé 385"
                d="M184.967,33.655c2.972,2.972-5.2,8.689-9.857,9.857,1.168-4.662,6.9-12.818,9.857-9.857Z"
                transform="translate(310.037 160.174)"
              />
              <path
                id="Tracé_386"
                data-name="Tracé 386"
                d="M141.775,33.655c2.972-2.972,8.689,5.2,9.857,9.857-4.662-1.168-12.818-6.9-9.857-9.857Z"
                transform="translate(305.474 160.174)"
              />
            </g>
          </svg>
          <h2 class="to-translate" data-en="ach out to us" data-de="n Sie uns">
            z-nous
          </h2>
        </div>
        <p
          class="to-translate"
          data-en="to make your future projects possible today."
          data-de="Damit Sie heute schon Ihre Projekte von morgen in Angriff nehmen können."
        >
          Afin de rendre possible<br />
          dès aujourd’hui vos projets de demain.
        </p>
      </div>
      <div class="contact-info">
        <div class="contact-text">
          <p>
            <img src="@/assets/img/icons/contact-location-point.svg" alt="" />
            <span>
              <span class="to-translate" data-en="eurecap GmbH" data-de="eurecap GmbH">eurecap Sàrl</span><br />c/o OBC Suisse AG<br />Aeschengraben 29<br />CH-4051
              <span class="to-translate" data-en="Bâle" data-de="Basel">Bâle</span>
            </span>
          </p>
          <p>
            <img src="@/assets/img/icons/contact-earth.svg" alt="" />
            www.eurecap.ch
          </p>
          <p>
            <img src="@/assets/img/icons/contact-phone.svg" alt="" />
            +41 79 861 14 26
          </p>
          <p>
            <img src="@/assets/img/icons/contact-mail.svg" alt="" />
            hello@eurecap.ch
          </p>
        </div>
      </div>
      <a
        class="iframe-container"
        target="_blank"
        href="https://www.openstreetmap.org/#map=19/47.54909/7.59103%22%3E"
      >
        <iframe
          width="425"
          height="350"
          src="https://www.openstreetmap.org/export/embed.html?bbox=7.591547369956971%2C47.54896987518518%2C7.5933176279068%2C47.54978454134573&amp;layer=hot&amp;marker=47.549377209848316%2C7.592432498931885"
          style="border: 1px solid black"
        ></iframe>
      </a>
      <h4
        class="to-translate transition-x"
        data-en="A great story always begins with an encounter."
        data-de="Eine Erfolgsgeschichte beginnt immer mit einer Begegnung."
      >
        Une belle histoire commence toujours par une rencontre.
      </h4>
    </section>
    <section class="contact-right">
      <form @submit.prevent="submitForm">
        <div class="contact-input">
          <div class="img-container">
            <img src="@/assets/img/icons/contact-first-name.svg" alt="" />
          </div>
          <input
            class="to-translate"
            data-en="First & Last name"
            data-de="Name & Vorname"
            placeholder="Nom & Prénom"
            type="text"
            id="name"
            v-model="formData.name"
            required
          />
        </div>
        <div class="contact-input">
          <div class="img-container">
            <img
              src="@/assets/img/icons/contact-phone.svg"
              alt=""
              class="filter"
            />
          </div>
          <input
            class="to-translate"
            data-en="Phone Number"
            data-de="Telefonnummer"
            placeholder="Numéro de téléphone"
            type="tel"
            id="tel"
            pattern="[+]?(?:\d\s?){6,14}\d"
            v-model="formData.tel"
            required
          />
        </div>
        <div class="contact-input">
          <div class="img-container">
            <img
              src="@/assets/img/icons/contact-mail.svg"
              alt=""
              class="filter"
            />
          </div>
          <input
            class="to-translate"
            data-en="Mail"
            data-de="Mail"
            placeholder="Mail"
            type="email"
            id="email"
            v-model="formData.email"
            required
          />
        </div>
        <div class="contact-input">
          <div class="img-container">
            <img src="@/assets/img/icons/contact-company.svg" alt="" />
          </div>
          <input
            class="to-translate"
            data-en="Company"
            data-de="Firma"
            placeholder="Société"
            type="text"
            id="company"
            v-model="formData.company"
          />
        </div>
        <div class="contact-input">
          <textarea
            class="to-translate"
            data-en="Message"
            data-de="Nachricht"
            id="message"
            placeholder="Message"
            v-model="formData.message"
            required
          ></textarea>
        </div>
        <button type="submit">Envoyer</button>
        <p v-if="message" :style="'color:' + { messageColor }">{{ message }}</p>
      </form>
    </section>
  </main>
</template>
<script>
import imgObserver from "@/assets/js/ImgObserver";

export default {
  name: "ContactView",
  data() {
    return {
      formData: {
        name: "",
        tel: "",
        email: "",
        company: "",
        message: "",
      },
      message: "",
      messageColor: "",
      showSVG: true,
    };
  },
  mounted() {
    imgObserver(document.querySelectorAll("img"));
    const links = document.querySelectorAll("header nav a");
    links.forEach((link) => {
      link.addEventListener("click", () => {
        if (
          link.classList.contains("active") &&
          link.getAttribute("data-flag") !== "en"
        ) {
          this.showSVG = false;
        }
      });
    });
  },
  methods: {
    async submitForm() {
      console.log(JSON.stringify(this.formData));
      try {
        const response = await fetch("/server/sendmail.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.formData),
        });
        const data = await response.json();
        if (response.ok && data.success) {
          this.message = "Email Sent with Success !";
          this.messageColor = "#2DE52D";
        } else {
          this.message =
            "Erreur lors de l'envoi du message, veuillez nous contacter à hello@eurecap.ch";
          this.messageColor = "#FF3232";
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/config/variables';
.contact {
  padding: 130px 5vw 75px 5vw;
  display: flex;
  gap: 50px;
  justify-content: space-between;
  .contact-left {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 45px;
    width: 60%;
    color: $grey;
    .iframe-container {
      width: 100%;
      height: 100%;
    }
    .contact-hero-banner-text {
      width: 100%;
      text-align: left;
      .contact-title {
        display: flex;
        align-items: center;
        gap: 3px;
        white-space: nowrap;
        svg {
          transform: translateY(-5px);
          -webkit-transform: translateY(-5px);
          width: clamp(1.5rem, 100%, 1.9rem);
          object-fit: contain;
          height:min-content;
          min-width: 22px ;
          min-height: 22px;
          fill: $orange;
        }
        @media screen and (max-width: 1000px) {
          svg {
            transform:scale(.8) translateY(-6px) !important;
          }
        }

        }
      }
    }
    .contact-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: 40px;
      div {
        display: flex;
        flex-direction: column;
        gap: 30px;
        p {
          display: flex;
          align-items: center;
          gap: 20px;
          hyphens: auto;
          word-wrap: break-word;
          img {
            min-width: 2rem;
            max-width: 2rem;
            height: 100%;
            filter: invert(79%) sepia(61%) saturate(4365%) hue-rotate(3deg) brightness(99%) contrast(106%);
            object-fit: contain;
          }
        }
      }
    }
    iframe {
      width: 100%;
      height: 100%;
      min-height:25vh;
    }
    h4 {
      text-align: center;
      transform:translateY(-10px);
    }
  }
  .contact-right {
    min-width: 40%;
    width: 40%;
    form {
      padding: 80px;
      background: $black;
      display: flex;
      align-items: center;
      gap:30px;
      flex-direction: column;
      div {
        display: flex;
        align-items: center;
        width: 100%;
        input, textarea {
          font-family: Arial, sans-serif;
          width: 100%;
          padding: 18.5px 16px;
          background: white;
          font-size: 16px;
          &::placeholder {
            font-size: 16px;
          }
          &#message {
            height: 160px;
            font-size: 14px;
            &::placeholder{
              font-family: Arial, sans-serif;
            }
          }
        }
        .img-container {
          padding: 5px;
          background: $orange;
          width:55px;
          height:55px;
          aspect-ratio: 1;
          img {
            width: 100%;
            padding: 4px;
            height: 100%;
            object-fit: contain;
            &.filter {
              filter: invert(99%) sepia(8%) saturate(277%) hue-rotate(203deg) brightness(112%) contrast(100%);
            }
          }
        }
      }
      button{
        text-align: center;
        text-transform: uppercase;
        padding: 20px 45px;
        background: #F7A600;
        border-radius: 5px;
        font-family: get-font-family(700), sans-serif;
        color: white;
      }
      p{
        color: white;
        font-family: get-font-family(800), sans-serif;
        margin-top:10px;
      }
    }
  }
@media (max-width: 1000px) {
  .contact {
    flex-direction: column;
    align-items: center;
    .contact-left {
      width: 100%;
      min-width: 100%;
      .contact-info {
        width: 35%;
      }
      .contact-hero-banner-text {
        text-align: center;
        .contact-title {
          justify-content: center;
          svg {
            transform: translateY(-2px);
            -webkit-transform: translateY(-2px);
          }
        }
      }
    }
    .contact-right {
      width: 85%;
    }
  }
}
@media (max-width: 550px){
  .contact-right{
    min-width: 95% !important;
    form {
      padding:50px 40px !important;
      input::placeholder{
        font-size: 14px;
      }
    }
  }
}
</style>
