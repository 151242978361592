export default function translate (lang) {
  const translationEl = document.querySelectorAll('.to-translate')
  translationEl.forEach(el => {
    if (el.hasAttribute('data-fr')) {
      if (el.hasAttribute('placeholder')) {
        el.placeholder = el.getAttribute(`data-${lang}`)
      } else {
        el.textContent = el.getAttribute(`data-${lang}`)
      }
    } else {
      if (el.hasAttribute('placeholder')) {
        el.setAttribute('data-fr', el.placeholder)
        el.placeholder = el.getAttribute(`data-${lang}`)
      } else {
        el.setAttribute('data-fr', el.textContent)
        el.textContent = el.getAttribute(`data-${lang}`)
      }
    }
  })
}
