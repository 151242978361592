<script setup>
import EnSavoirPlus from '@/components/EnSavoirPlus.vue'
import TitleView from '@/components/HomeTitle.vue'
</script>
<template>
    <section class="brainstorming">
        <TitleView
        class="white"
        :label="'Contactez nous'"
        :title="'Séance de brainstorming gratuite (30 minutes)'"
        :labelDe="'Kontaktieren Sie uns'"
        :labelEn="'Contact Us'"
        :TitleEn="'Free brainstorming session (30 minutes)'"
        :TitleDe="'Kostenlose Brainstorming-Sitzung (30 Minuten)'"
      />
      <EnSavoirPlus where="/contact"/>
      <img src="@/assets/img/carine-img.png" alt="">
    </section>
</template>
<script>
export default {
    name: 'BrainStorming',
    components: {
        EnSavoirPlus,
        TitleView
    },
}
</script>