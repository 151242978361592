<template>
  <div class="footer-menu">
  <h2
    class="to-translate"
    data-en="Cookies policy"
    data-de="Cookie-Richtlinie"
  >
    Politique en matière de cookies
  </h2>
  <titleFooter class="title"
    text="1. Qu’est-ce qu’un cookie ?"
    textEn="1. What is a cookie?"
    textDe="1. Was sind Cookies?"
  />
  <p
    class="to-translate"
    data-en="A cookie is a small file consisting of letters and numbers that is downloaded to your computer when you access certain websites. In general, cookies enable a website to recognize a user’s computer. The most important thing to know about the cookies that we place is that they are used to improve the user-friendliness of our website, for example by remembering site preferences and language settings."
    data-de="Cookies sind kleine, aus Zahlen und Buchstaben bestehende Dateien, die beim Besuch bestimmter Websites auf Ihrem Computer abgespeichert werden. Cookies ermöglichen es einer Website in der Regel, den Computer des Nutzers wiederzuerkennen."
  >
    Un cookie est un petit fichier constitué de lettres et de chiffres et téléchargé sur
    votre ordinateur lorsque vous accédez à certains sites web. En général, les cookies
    permettent à un site web de reconnaître l’ordinateur de l’utilisateur.

    La chose la plus importante à savoir sur les cookies que nous plaçons est qu’ils
    servent à améliorer la convivialité de notre site web, par exemple en mémorisant
    les préférences du site et les paramètres linguistiques.
  </p>
  <titleFooter class="title"
    text="2. Pourquoi utilisons-nous des cookies ?"
    textEn="2. Why do we use cookies?"
    textDe="2. Weshalb verwenden wir Cookies?"
  />
  <p
    class="to-translate"
    data-en="We may use cookies and other similar technologies for a number of reasons, for example: i) for security or fraud-protection purposes, and to identify and prevent cyber-attacks; ii) to provide you with the service you have chosen to receive from us; iii) to monitor and analyse the performance, operation and effectiveness of our service and iv) to improve your user experience."
    data-de="Wir können Cookies oder ähnliche Technologien aus verschiedenen Gründen einsetzen, zum Beispiel: i) aus Sicherheitsüberlegungen oder zum Schutz vor Betrug sowie zur Erkennung und Prävention von Cyberangriffen; ii) um Ihnen die Dienstleistungen zu bieten, die Sie von uns erhalten möchten; iii) zur Kontrolle und zur Analyse der Performance, der Funktionstüchtigkeit und der Effizienz unserer Dienstleistung; und iv) zur Verbesserung Ihrer Benutzererfahrung."
  >
    Nous pouvons utiliser des cookies et d’autres technologies similaires pour un
    certain nombre de raisons, par exemple : i) pour des besoins de sécurité ou de
    protection contre la fraude, et afin d’identifier et de prévenir les cyber-attaques, ii)
    pour vous fournir le service que vous avez choisi de recevoir de notre part, iii) pour
    contrôler et analyser les performances, le fonctionnement et l’efficacité de notre
    service et iv) d’améliorer votre expérience utilisateur.

  </p>
  <titleFooter class="title"
    text="3. Vos choix :"
    textEn="3. Your choices:"
    textDe="3. Ihre Auswahl:"
  />
  <p
    class="to-translate"
    data-en="To find out more about cookies, including how to see which cookies have been set and to understand how to manage, delete or block them, visit https://aboutcookies.org/ or https://www.allaboutcookies.org/fr/. It is also possible to prevent your browser from accepting cookies by modifying the relevant settings in your browser. You can generally find these settings in your browser’s “Options” or “Preferences” menu browser. Please note that deleting our cookies or disabling future cookies or tracking technologies may prevent you from accessing certain areas or functionalities of our services, or may otherwise adversely affect your user experience."
    data-de="Wenn Sie mehr über Cookies erfahren möchten, insbesondere darüber, wie Sie die hinterlegten Cookies erkennen und verstehen können, wie Sie diese verwalten, löschen oder blockieren können, gehen Sie auf https://aboutcookies.org/ oder https://www.allaboutcookies.org/fr/. Sie können Ihren Browser auch daran hindern, Cookies zu akzeptieren, indem Sie die betreffenden Parameter in Ihrem Browser anpassen. Sie finden diese Parameter normalerweise im Menü «Optionen» oder «Präferenzen» Ihres Browsers. Beachten Sie aber, dass die Unterdrückung unserer Cookies oder die Deaktivierung künftiger Cookies oder Tracking-Technologien verhindern können, dass Sie Zugang zu bestimmten Bereichen oder Funktionen unserer Dienstleistungen erhalten oder dass Ihre Benutzererfahrung anderweitig beeinträchtigt wird. "
  >
    Nous pouvons utiliser des cookies et d’autres technologies similaires pour un
    certain nombre de raisons, par exemple : i) pour des besoins de sécurité ou de
    protection contre la fraude, et afin d’identifier et de prévenir les cyber-attaques, ii)
    pour vous fournir le service que vous avez choisi de recevoir de notre part, iii) pour
    contrôler et analyser les performances, le fonctionnement et l’efficacité de notre
    service et iv) d’améliorer votre expérience utilisateur.
  </p>
  <div>
    <p class="to-translate" style="text-decoration:underline;" data-en="The following links may be useful, or else you can use your browser's “Help” option." data-de="Unter folgenden Links finden Sie weitere Informationen. Sie können auch die «Hilfe»-Funktion Ihres Browsers verwenden.">
      Les liens suivants peuvent être utiles, ou vous pouvez utiliser l’option « Aide » de
      votre navigateur.
    </p>
    <ul class="strong">
      <li>
        <a href="https://support.mozilla.org/en-US/kb/websites-say-cookies-are-blocked-unblock-them"> Parameters of cookies in Internet Explorer</a>
      </li>
      <li>
        <a href="https://support.microsoft.com/fr-fr/topic/supprimer-et-g%C3%A9rer-les-cookies-168dab11-0753-043d-7c16-ede5947fc64d"> Parameters of cookies in Google Chrome</a>
      </li>
      <li>
        <a href="https://support.apple.com/fr-fr/guide/safari/sfri11471/mac"> Parameters of cookies in Safari (OS X)</a>
      </li>
      <li>
        <a href="https://support.apple.com/fr-fr/HT201265"> Parameters of cookies in Safari (iOS)</a>
      </li>
      <li>
        <a href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DAndroid&hl=fr"> Parameters of cookies in Android</a>
      </li>
    </ul>
    <p class="to-translate" data-en="To refuse and prevent your data from being used by Google Analytics on all websites, please consult the following instructions: https://tools.google.com/dlpage/gaoptout?hl=fr. We may modify this cookie policy from time to time.  We encourage you to read this page regularly to get the latest information on cookies." data-de="Fürs Ablehnen und Verhindern, dass Ihre Daten von Google Analytics auf allen Websiten verwendet werde, finden Sie hier entsprechende Informationen: https://tools.google.com/dlpage/gaoptout?hl=de. Unsere Cookie-Richtlinie kann sich unter Umständen ändern. Bitte konsultieren Sie diese Seite regelmässig, um über die jüngsten Informationen betreffend Cookies auf dem Laufenden zu bleiben.">
      Pour refuser et empêcher que vos données soient utilisées par Google Analytics
      sur tous les sites web, consultez les instructions suivantes :
      <a href="https://tools.google.com/dlpage/gaoptout?hl=fr">https://tools.google.com/dlpage/gaoptout?hl=fr.</a>
      Il se peut que nous modifiions cette politique en matière de cookies. Nous vous
      encourageons à consulter régulièrement cette page pour obtenir les dernières
      informations sur les cookies.</p>
  </div>
  </div>
</template>
<script>
import titleFooter from '@/components/footer/TitleFooterMenu.vue'

export default {
  name: 'CookieView',
  components: {
    titleFooter
  }
}
</script>
<style lang="scss" scoped>
.title {
  font-size: 25px;
  font-weight: 550;
}
.strong li a {
  font-weight: 550;
}
ul {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px 0;
}
</style>