<template>
    <section class="hero-banner">

        <img :src="src" alt="">
        <div class="hero-banner-text">
          <div class="hero-banner-title">
            <h1 style="display: none;">{{ titleFirstPartEn + titleSecondPart }}</h1>
            <h2 class="to-translate" :data-en="titleFirstPartEn" :data-de="titleFirstPartDe">{{ titleFirstPart }}</h2>
            <div class="title-second-part">
              <h2 class="to-translate" :data-en="titleSecondPart1En" :data-de="titleSecondPart1De"> {{titleSecondPart1}} </h2>
              <svg xmlns="http://www.w3.org/2000/svg" width="50.886" height="78.916" viewBox="0 0 50.886 78.916">
                <g id="Groupe_98" data-name="Groupe 98" transform="translate(-445.332 -182.157)">
                  <path id="Tracé_383" data-name="Tracé 383" d="M190.7,76H149.255a16.017,16.017,0,0,0,5.933,10.844,19.321,19.321,0,0,0,12.58,4.095q9.154,0,14.746-6.171l5.1,5.978A22.492,22.492,0,0,1,179,96.914a32.519,32.519,0,0,1-25.874-1.2,23.71,23.71,0,0,1-9.687-9.3,26.686,26.686,0,0,1-3.426-13.5,26.943,26.943,0,0,1,3.324-13.4,23.745,23.745,0,0,1,9.154-9.3,26.133,26.133,0,0,1,13.158-3.324A25.574,25.574,0,0,1,178.7,50.214a23.351,23.351,0,0,1,8.961,9.3A28.293,28.293,0,0,1,190.9,73.207,27.577,27.577,0,0,1,190.7,76ZM154.518,58.744a16.314,16.314,0,0,0-5.252,10.606h32.771A16.414,16.414,0,0,0,176.785,58.8a17.24,17.24,0,0,0-22.256-.045Z" transform="translate(305.322 162.037)" :fill="color"/>
                  <path id="Tracé_384" data-name="Tracé 384" d="M163.085,23.29c4.685,0,2.756,13.748,0,19.522-2.756-5.774-4.673-19.522,0-19.522Z" transform="translate(308.042 158.867)" :fill="color"/>
                  <path id="Tracé_385" data-name="Tracé 385" d="M184.967,33.655c2.972,2.972-5.2,8.689-9.857,9.857,1.168-4.662,6.9-12.818,9.857-9.857Z" transform="translate(310.037 160.174)" :fill="color"/>
                  <path id="Tracé_386" data-name="Tracé 386" d="M141.775,33.655c2.972-2.972,8.689,5.2,9.857,9.857-4.662-1.168-12.818-6.9-9.857-9.857Z" transform="translate(305.474 160.174)" :fill="color"/>
                </g>
              </svg>
              <h2 class="to-translate" :data-en="titleSecondPartEn" :data-de="titleSecondPartDe"> {{ titleSecondPart }} </h2>
            </div>
          </div>

          <p class="hero-banner-desc to-translate" :data-en="paragrapheEn" :data-de="paragrapheDe">
            {{ paragraphe }}
          </p>
        </div>
      </section>
</template>
<script>
export default {
  name: 'HeroBanner',
  props: {
    src: String,
    titleFirstPart: String,
    titleSecondPart: String,
    paragraphe: String,
    color: String,
    titleFirstPartEn: String,
    titleSecondPartEn: String,
    paragrapheEn: String,
    titleFirstPartDe: String,
    titleSecondPartDe: String,
    paragrapheDe: String,
    titleSecondPart1: String,
    titleSecondPart1En: String,
    titleSecondPart1De: String
  }
}
</script>
