<template>
    <div class="title-container">
        <div class="title-label">
          <hr>
          <p class="to-translate" :data-en="labelEn" :data-de="labelDe">{{label}}</p>
          <hr>
        </div>
        <h2 class="to-translate" :data-en="TitleEn" :data-de="TitleDe">{{title}}</h2>
      </div>
</template>

<script>
export default {
  name: 'TitleView',
  props: {
    label: String,
    title: String,
    labelEn: String,
    labelDe: String,
    TitleEn: String,
    TitleDe: String
  }
}
</script>
