<script>
export default {
  name: 'ImpressumView'
}
</script>

<template>
  <div class="footer-menu">
  <h2 class="to-translate" data-en="Publication details" data-de="Impressum">Impressum</h2>
  <p class="to-translate" data-en="Address" data-de="Adresse"><strong>Adresse</strong></p>
  <ul>
    <li class=to-translate data-en="Eurecap Sàrl" data-de="eurecap GmbH">Eurecap Sàrl</li>
    <li>c/o OBC Suisse</li>
    <li>Aeschengraben 29</li>
    <li>4051 Bâle</li>
    <li class="to-translate" data-en="Switzerland" data-de="Schweiz">Suisse</li>
  </ul>

  <p class="to-translate" data-en="Management" data-de="Direktion"><strong>Direction</strong></p>
  <p class="subtitles">Carine Meierhans</p>

  <p class="to-translate" data-en="Contact details" data-de="Kontaktadresse"><strong>Contact</strong></p>
  <ul>
    <li>Email : info@eurecap.ch</li>
    <li>+41 79 861 14 26</li>
  </ul>
  <p class="to-translate" data-en="Legal form" data-de="Rechtsform"><strong>Forme juridique</strong></p>
  <ul>
    <li class="to-translate" data-en="Sàrl with a capital of CHF 20,000" data-de="GmbH mit einem Kapital von CHF 20’000">Sàrl au capital de CHF 20’000</li>
    <li>IDE/UID: CHE-294.184.730</li>
    <li class="to-translate" data-en="Federal number: CH-270-4008422-2" data-de="Nummer Eidgenöss. Handelsregister: CH-270-4008422-2">Numéro fédéral: CH-270-4008422-2</li>
    <li class="to-translate" data-en="VAT number: CHE-294.184.730 MWST" data-de="MWST-Nummer: CHE-294.184.730 MWST">Numéro TVA : CHE-294.184.730 MWST</li>
  </ul>

  <p class="to-translate" data-en="Website" data-de="Website"><strong>Site internet</strong></p>
  <p class="to-translate subtitles" data-en="Webmaster (Graphics, webdesign & maintenance): Paul Guttin (paul.guttin@gmail.com) & Louis Leschevin (louis.leschevin@gmail.com)" data-de="Webmaster (Grafik, Webdesign & Wartung): Paul Guttin (paul.guttin@gmail.com) & Louis Leschevin (louis.leschevin@gmail.com)">Webmaster (Graphisme, webdesign & maintenance): Paul Guttin (paul.guttin@gmail.com) & Louis Leschevin (louis.leschevin@gmail.com)</p>

  <p class="to-translate" data-en="Hosting" data-de="Hosting"><strong>Hébergement</strong></p>
  <p class="to-translate subtitles" data-en="This site is hosted by Hosttech GmbH" data-de="Diese Website wird von Hosttech GmbH gehostet">Ce site est hébergé chez Hosttech GmbH</p>
  </div>
</template>
