<template>
    <main class="about">
      <HeroBanner
        :src="aboutHeroBanner"
        titleFirstPart='Notre exp'
        titleSecondPart='rtise'
        paragraphe='Carine Meierhans a fondé eurecap après une carrière de plus de 20 ans dans le secteur FMCG et retail en Suisse.'
        titleFirstPartEn="Our&nbsp;"
        titleSecondPartEn="xpertise"
        paragrapheEn="Carine Meierhans established eurecap after a career spanning more than 20 years in the FMCG and retail sectors in Switzerland."
        titleFirstPartDe="Unsere Komp"
        titleSecondPartDe="tenzen"
        paragrapheDe="Carine Meierhans hat eurecap nach einer über 20-jährigen Laufbahn im FMCG- und Retailbereich in der Schweiz gegründet."
        color='#F7A600'
        />
      <section id="about-quotes-container">
        <AboutQuotes
          v-for="(item, index) in AboutQuotesItems"
          :key="index"
          :title="item.title"
          :quote="item.quote"
          :titleEn="item.titleEn"
          :titleDe="item.titleDe"
          :quoteDe="item.quoteDe"
          :quoteEn="item.quoteEn"
        />
      </section>
      <section id="about-brainstorming">
        <TitleView
        class="white"
        :label="'Contactez nous'"
        :title="'Séance de brainstorming gratuite (30 minutes)'"
        :labelDe="'Kontaktieren Sie uns'"
        :labelEn="'Contact Us'"
        :TitleEn="'Free brainstorming session (30 minutes)'"
        :TitleDe="'Kostenlose Brainstorming-Sitzung (30 Minuten)'"
      />
        <EnSavoirPlus where="/contact"/>
        <img src="@/assets/img/carine-img.png" alt="">
        <QuoteComponent
           class="quote-white"
           :autor="'Jacques Brel'"
           :quote="'Le talent, c’est d’avoir l’envie de faire quelque chose.'"
           :linebreak="'Tout le reste, c’est de la sueur.'"
           :linebreakDe="'Der Rest ist harte Arbeit.'"
           :linebreakEn="'Everything else is sweat.'"
           :quoteEn="'Talent is having the desire to do something.'"
           :quoteDe="'Talent haben bedeutet, etwas anpacken zu wollen.'"
        />
      </section>
    </main>
  </template>
  <script>
  import EnSavoirPlus from '@/components/EnSavoirPlus.vue'
  import HeroBanner from '@/components/HeroBanner.vue'
  import AboutQuotes from '@/components/AboutQuotes.vue'
  import TitleView from '@/components/HomeTitle.vue'
  import QuoteComponent from '@/components/QuoteComponent.vue'
  import imgObserver from '@/assets/js/ImgObserver'
  export default {
    name: 'AboutView',
    components: {
      HeroBanner,
      AboutQuotes,
      TitleView,
      EnSavoirPlus,
      QuoteComponent
    },
    data () {
      return {
        aboutHeroBanner: require('@/assets/img/about-hero-banner.png'),
        AboutQuotesItems: [
          {
            title: 'Pourquoi implanter eurecap en Suisse, à Bâle?',
            titleEn: 'Why locate eurecap in Basel, Switzerland?',
            titleDe: 'Warum ist eurecap in der Schweiz bzw. in Basel angesiedelt?',
            quote: 'La Suisse était pour moi une évidence car c’est un marché que je connais et qui me correspond. Après 2 ans au sein d’une agence de marketing direct en Allemagne, j’ai embrassé une carrière de plus de 20 ans dans la région de Bâle et d’Argovie. J’aime le sens de la rigueur dont savent faire preuve les Suisses dans le travail, allié malgré tout à une certaine bienveillance et une ouverture d’esprit, très marquée à Bâle, ville cosmopolite.',
            quoteEn: 'Switzerland was an obvious choice for me because it’s a market I know and that suits me. After spending two years with a direct marketing agency in Germany, I embarked on a career of more than 20 years in the Basel and Aargau regions. I like the rigour that the Swiss display in their work, combined nonetheless with a degree of benevolence and open-mindedness, which is very pronounced in Basel, a cosmopolitan city.',
            quoteDe: 'Die Entscheidung für die Schweiz erschien mir logisch, da ich diesen Markt gut kenne und er mich anspricht. Nach zwei Jahren in einer Agentur für Direktmarketing in Deutschland war ich über zwanzig Jahre lang in den Regionen Basel und Aargau tätig. Ich mag die Zuverlässigkeit, mit der in der Schweiz gearbeitet wird, aber auch die positive Einstellung und die Offenheit, die in der kosmopolitischen Stadt Basel besonders stark zu spüren sind.'
          },
          {
            title: 'Les agences de consulting foisonnent, pourquoi eurecap?',
            titleEn: 'There’s a plethora of consulting agencies, why eurecap?',
            titleDe: 'Marketingagenturen gibt es wie Sand am Meer. Warum braucht es eurecap?',
            quote: 'eurecap est une petite structure qui se veut agile et pragmatique. Avec eurecap, vous avez une seule personne de contact (moi-même). eurecap est née d’une constatation que j’ai faite en tant que manager: il est extrêmement difficile de recruter du personnel qualifié pour des missions à court terme (remplacement maternité, longue maladie, gel des embauches). A travers eurecap, j’aimerais mettre mon expertise au service des PME ou des grands groupes qui se retrouvent en difficulté suite à un manque ponctuel de ressources marketing qualifiées.',
            quoteEn: 'eurecap is a small company that is intended to be agile and pragmatic. With eurecap, you have a single contact person (myself). eurecap was formed as a result of an observation I made as a manager:  it’s extremely difficult to recruit qualified staff for short-term assignments (maternity replacements, long-term illness, recruitment freezes).  Through eurecap, I would like to make my expertise available to SMEs or large groups that find themselves in difficulty due to a temporary lack of qualified marketing resources.',
            quoteDe: 'eurecap ist ein kleines Unternehmen, das agil und pragmatisch arbeitet. Bei eurecap haben Sie immer eine einzige Ansprechperson. eurecap entstand aus einer Erkenntnis, die ich als Managerin machte: Es ist bei kurzfristigen Aufträgen (Stellvertretungen bei Mutterschaft, langer Krankheit, Einstellungsstopp) extrem schwierig, qualifiziertes Personal zu finden. Mit eurecap möchte ich meine Kompetenzen und mein Wissen in den Dienst von KMU oder Konzernen stellen, die Schwierigkeiten haben, für befristete Aufträge qualifizierte Marketing-Fachleute zu finden.'
          },
          {
            title: 'Quelle est la compétence centrale de eurecap?',
            titleEn: 'What is eurecap’s core competency?',
            titleDe: 'Welches ist die grösste Stärke von eurecap?',
            quote: 'eurecap accompagne ses clients dans la mise en place de campagnes marketing impactantes et mesurables. J’ai également une affinité particulière pour les projets de développement business B2B, notamment dans des environnements techniques (interface avec l’IT, CRM…). De par ma longue carrière dans le Brand Management, Trade Marketing et CRM, je suis capable de m’adapter à beaucoup de situations. J’étudie chaque demande au cas par cas après une séance de brainstorming. Mon temps est limité, le vôtre aussi, et je privilégie les actions pour lesquelles je peux apporter au client une réelle valeur ajoutée.',
            quoteEn: 'eurecap helps its customers to put in place impactful and measurable marketing campaigns. I also have a particular affinity for B2B business development projects, especially in technical environments (interfacing with IT, CRM, etc.). Thanks to my long career in Brand Management, Trade Marketing and CRM, I’m able to adapt to a wide range of situations. I study each request on a case-by-case basis after a brainstorming session. My time is limited, as is yours, and I give priority to the actions for which I can provide the customer with genuine added value.',
            quoteDe: 'eurecap unterstützt ihre Kunden bei der Durchführung von wirkungsvollen Marketingkampagnen mit messbarem Erfolg. Wir sind auch spezialisiert auf B2B-Projekte, insbesondere in einem technischen Umfeld (IT-Schnittstelle, CRM usw.). Aufgrund meiner langjährigen beruflichen Tätigkeit im Brand Management, Trade Marketing und CRM passe ich mich schnell an neue Situationen an. Ich prüfe jede einzelne Anfrage nach einer Brainstorming-Sitzung. Ihre Zeit ist begrenzt, meine auch, und ich bevorzuge Aufträge, bei denen ich Ihnen als Kunden einen echten Mehrwert bieten kann.'
          }
        ]
      }
    },
    mounted () {
      imgObserver(document.querySelectorAll('img'))
    }
  }
  </script>