<template>
  <main>
    <HeroBanner
    class="ourSolutions-hero-banner"
    :src="OurSolutionsHeroBanner"
    titleFirstPart="Mark"
    titleSecondPart="ting client"
    paragraphe="Nous imaginons et déclenchons des rencontres qui comptent et qui durent - entre vous et vos prospects, vous et vos clients, vous et vos réseaux de vente."
    titleFirstPartEn="Custom"
    titleSecondPartEn="r marketing"
    paragrapheEn="We put all our heart and mind into devising and triggering meetings that matter and last – between you and your prospects, you and your customers, you and your sales networks."
    titleFirstPartDe="Kund"
    titleSecondPartDe="nmarketing"
    paragrapheDe="Wir setzen uns mit Herzblut dafür ein, solide und langlebige Beziehungen zu planen und zu verwirklichen – zwischen Ihnen und potenziellen oder bestehenden Kunden, zwischen Ihnen und Ihren Vertriebsnetzen."
    color="#535353"
    />
    <section class="about-grid">
      <img src="@/assets/img/stairs-and-computer.png" alt="">
      <OurSolutionsCard
        v-for="(card, index) in cardItem"
        :key="index"
        :cardTitle="card.cardTitle"
        :cardDesc="card.cardDesc"
        :cardListTitle="card.cardListTitle"
        :cardList="card.cardList"
        :class="card.class"
      />
    </section>
  </main>
</template>

<script>
import HeroBanner from '@/components/HeroBanner.vue'
import OurSolutionsCard from '@/components/OurSolutionsCard.vue'
import imgObserver from '@/assets/js/ImgObserver'

export default {
  name: 'OurSolutionsView',
  components: {
    HeroBanner,
    OurSolutionsCard
  },
  data () {
    return {
      OurSolutionsHeroBanner: require('@/assets/img/ourSolutions-hero-banner.png'),
      cardItem: [
        {
          cardTitle: {
            text: 'Le marketing relationnel',
            en: 'Relationship Marketing',
            de: 'Beziehungsmarketing'
          },
          cardDesc: {
            text: 'Notre cœur de métier, c’est de créer une relation durable et profitable avec vos clients. eurecap intervient dans le domaine du marketing relationnel pour vous permettre d’atteindre vos objectifs commerciaux grâce à la mise en place de campagnes marketing pertinentes.',
            en: 'Our core business is to create a lasting and profitable relationship with your customers.Thanks to personalized and relevant marketing campaigns, eurecap helps you create a unique customer relationship and achieve your business goals.',
            de: 'Unser Kerngeschäft besteht darin, eine dauerhafte und profitable Beziehung zu Ihren Kunden aufzubauen. Dank personalisierter und relevanter Marketingkampagnen hilft Ihnen eurecap, ein einzigartiges Kundenverhältnis zu kreieren, und ihre Geschäftsziele zu erreichen.'
          },
          cardListTitle: {
            text: 'Nos compétences:',
            en: 'Our core competence:',
            de: 'Unser Kompetenzbereich:'
          },
          cardList: [
            {
              text: 'Segmentation et analyse de données',
              en: 'Segmentation and data analysis',
              de: 'Segmentierung und Datenanalyse'
            },
            {
              text: 'Stratégies commerciales',
              en: 'Sales strategies',
              de: 'Vertriebsstrategien'
            },
            {
              text: 'Campagnes marketing',
              en: 'Marketing campaigns',
              de: 'Marketingkampagnen'
            },
            {
              text: 'Marketing du Shopper',
              en: 'Shopper marketing',
              de: 'Shopper-Marketing'
            },
          ],
          class: 'about-card-orange'
        },
        {
          cardTitle: {
            text: 'Management de projet',
            en: 'Project Management',
            de: 'Projektmanagement'
          },
          cardDesc: {
            text: 'eurecap vous propose son expertise en matière de direction de projets marketing, notamment dans un environnement trilingue (fr./all./angl.) sur des projets complexes et techniques (Retail, CRM, B2B).',
            en: 'eurecap offers you its expertise in marketing project management, particularly in a trilingual environment (Eng./Fr./Ger.) for complex and technical projects (Retail, CRM, B2B).',
            de: 'eurecap bietet Ihnen ihre Expertise in der Leitung von Marketingprojekten, insbesondere in einem mehrsprachigen Umfeld (Dt./Frz./Engl.) oder bei komplexen und technischen Themen (Retail, CRM, B2B).'
          },
          cardListTitle: {
            text: 'Nos services :',
            en: 'Our services:',
            de: 'Unsere Dienstleistungen:'
          },
          cardList: [
            {
              text: 'Diriger le lancement d’un nouveau produit',
              en: 'Managing a new product launch',
              de: 'Produkteinführung kundenorientiert gestalten'
            },
            {
              text: 'Prendre en charge la migration clients lors d’un changement technique (site web, plateforme CRM)',
              en: 'Handling customer migration in the event of a technical change (website, CRM platform)',
              de: 'Kundenmigration im Falle einer technischen Änderung begleiten (Webseite, CRM) '
            },
            {
              text: 'Définir de nouveaux processus de vente',
              en: 'Defining new sales processes',
              de: 'Neue Vertriebsprozesse definieren'
            },
            {
              text: 'Former les équipes commerciales.',
              en: 'Training the sales teams.',
              de: 'Verkaufsteam schulen'
            }
          ],
          class: 'about-card-white'
        },
        {
          cardTitle: {
            text: 'Management de l’interim',
            en: 'Interim Management',
            de: 'Interim Management'
          },
          cardDesc: {
            text: 'Pour vous simplifier le recrutement de personnel qualifié, même sur une très courte mission, nous vous proposons d’intervenir pour quelques semaines ou quelques mois afin de soutenir votre équipe marketing en place.',
            en: 'To simplify the recruitment of qualified staff, even for very short assignments, we offer to step in to support your existing marketing team for a few weeks or months.',
            de: 'Um die Rekrutierung qualifizierter Mitarbeiter, selbst für sehr kurze Einsätze, zu vereinfachen, bieten wir an, Ihr bestehendes Marketingteam für einige Wochen oder Monate zu unterstützen.'
          },
          cardListTitle: {
            text: 'Nous prenons volontiers le relais en cas de',
            en: 'We are happy to take over in case of:',
            de: 'Wir springen in folgenden Fällen gerne ein:'
          },
          cardList: [
            {
              text: 'Congé maternité d’un employé',
              en: 'an employee taking maternity leave',
              de: 'Mutterschutz einer Mitarbeiterin'
            },
            {
              text: 'Longue maladie',
              en: 'long-term illness',
              de: 'Lange Krankheit'
            },
            {
              text: 'Gel des embauches en interne',
              en: 'an internal recruitment freeze',
              de: 'Einstellungsstopp des Unternehmens'
            },
            {
              text: 'Manque de ressources sur un projet ponctuel.',
              en: 'a lack of resources for a one-off project.',
              de: 'Fehlende Personalressourcen bei einem spezifischen Projekt.'
            }
          ],
          class: 'about-card-blue'
        }
      ]
    }
  },
  mounted () {
    imgObserver(document.querySelectorAll('img'))
    function insertSVGs () {
      function setAttributes (el, attrs) {
        for (const key in attrs) {
          el.setAttribute(key, attrs[key])
        }
      }

      const listArr = document.querySelectorAll('.about-card-list p')

      

      listArr.forEach((list) => {
        if (list.querySelector('svg')) return;

        const svgEl = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
        setAttributes(svgEl, {
          width: '12.511',
          height: '9.587',
          viewBox: '0 0 12.511 9.587'
        })

        svgEl.innerHTML = '<path d="M7.176,14.887,3.3,11.011l.952-.952,2.924,2.924L14.859,5.3l.952.952Z" transform="translate(-3.3 -5.3)" fill="#414141"/>'

        const pathEl = document.createElementNS('http://www.w3.org/2000/svg', 'path')
        setAttributes(pathEl, {
          d: 'M7.176,14.887,3.3,11.011l.952-.952,2.924,2.924L14.859,5.3l.952.952Z',
          transform: 'translate(-3.3 -5.3)',
          fill: '#414141'
        })

        svgEl.appendChild(pathEl)
        list.insertBefore(svgEl, list.firstChild)
      })
    }
    const translationContainerImgs = document.querySelectorAll('header #translation-container img')
    translationContainerImgs.forEach(img => img.addEventListener('click', insertSVGs))
    setTimeout(() => {
      insertSVGs()
    }, 1)
  }
}
</script>
<style lang="scss">
@import '@/assets/scss/config/variables';
.about-grid {
  background: $black;
  padding:100px 15vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 0;
  img {
    grid-row: 2;
  }
}
@media (max-width: 1500px) {
  .about-grid {
    padding:80px 5vw;
  }
}
@media (max-width: 1000px) {
  .about-grid {
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      display: none;
    }
  }
}
@media (max-width: 400px) {
  .about-grid {
    padding: 30px;
  }
}
</style>
