<template>
  <main>
    <section id="hero">
      <img src="../assets/img/hero-banner.png" alt="" />
      <div class="hero-content">
        <div class="hero-text">
          <h1
            class="to-translate vibing-el"
            data-en="Your customer marketing agency"
            data-de="Ihre Agentur für Kundenmarketing"
          >
            Votre agence marketing client
          </h1>
          <p
            class="to-translate vibing-el-delay"
            data-en="eurecap provides practical and effective assistance in the marketing sector. Advice and analysis are systematically used to foster the development and loyalty of your customer base."
            data-de="eurecap steht für konkrete, effiziente und persönliche Unterstützung im Bereich Marketing. Die Beratung und die Analyse werden systematisch in den Dienst der Kundenentwicklung und Kundenbindung gestellt."
          >
            eurecap apporte une aide <span class="orange">concrète</span>,
            <span class="orange">efficace</span> et
            <span class="orange">sans intermédiaire</span> dans le domaine du
            marketing et de la gestion de projets. Au delà du simple consulting,
            le conseil et l'analyse sont systématiquement mis au service de
            l'action.
          </p>
        </div>
        <EnSavoirPlus where="/our-solutions" />
      </div>
    </section>
    <section id="wording-container">
      <WordingView
        v-for="(item, index) in wordingItems"
        :key="index"
        :text="item.text"
        :src="item.src"
        :en="item.en"
        :de="item.de"
      />
    </section>
    <section id="home-grid">
      <div>
        <img :src="GridImg" alt="" />
      </div>
      <div>
        <p
          class="to-translate transition-x"
          data-en="Need skilled human resources for a business or marketing project?"
          data-de="Brauchen Sie zusätzliche Arbeitskräfte für ein Business- oder Marketingprojekt?"
        >
          Besoin de ressources qualifiées pour un projet business ou marketing ?
        </p>
        <hr />
        <p>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12.511"
              height="9.587"
              viewBox="0 0 12.511 9.587"
            >
              <path
                d="M7.176,14.887,3.3,11.011l.952-.952,2.924,2.924L14.859,5.3l.952.952Z"
                transform="translate(-3.3 -5.3)"
                fill="#414141"
              />
            </svg>
            <span
              class="to-translate transition-x"
              data-en="Deputizing during maternity leave, sabbaticals and long-term sick leave"
              data-de="Stellvertretungen bei Mutterschaftsurlaub, Sabbatical, Langzeiterkrankung"
            >
              Remplacement en cas de congés maternité, sabbatiques, longue
              maladie
            </span>
          </span>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12.511"
              height="9.587"
              viewBox="0 0 12.511 9.587"
            >
              <path
                d="M7.176,14.887,3.3,11.011l.952-.952,2.924,2.924L14.859,5.3l.952.952Z"
                transform="translate(-3.3 -5.3)"
                fill="#414141"
              />
            </svg>
            <span
              class="to-translate transition-x"
              data-en="Freeze on internal recruitments"
              data-de="Einstellungsstopp des Unternehmens"
            >
              Gel des embauches en interne
            </span>
          </span>
          <span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="12.511"
              height="9.587"
              viewBox="0 0 12.511 9.587"
            >
              <path
                d="M7.176,14.887,3.3,11.011l.952-.952,2.924,2.924L14.859,5.3l.952.952Z"
                transform="translate(-3.3 -5.3)"
                fill="#414141"
              />
            </svg>
            <span
              class="to-translate transition-x"
              data-en="Temporary staff management"
              data-de="Interim-Management"
            >
              Management de l’intérim
            </span>
          </span>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12.511"
              height="9.587"
              viewBox="0 0 12.511 9.587"
            >
              <path
                d="M7.176,14.887,3.3,11.011l.952-.952,2.924,2.924L14.859,5.3l.952.952Z"
                transform="translate(-3.3 -5.3)"
                fill="#414141"
              />
            </svg>
            <span
              class="to-translate transition-x"
              data-en="Seasonal assignments"
              data-de="Saisonale Einsätze"
            >
              Mission saisonnière
            </span>
          </span>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12.511"
              height="9.587"
              viewBox="0 0 12.511 9.587"
            >
              <path
                d="M7.176,14.887,3.3,11.011l.952-.952,2.924,2.924L14.859,5.3l.952.952Z"
                transform="translate(-3.3 -5.3)"
                fill="#414141"
              />
            </svg>
            <span
              class="to-translate transition-x"
              data-en="Managing a complex project, particularly one with technical implications"
              data-de="Projektmanagement im Fall von fehlenden Ressourcen"
            >
              Gestion d’un projet complexe, notamment à affinité technique
            </span>
          </span>
        </p>
      </div>
      <div>
        <p
          class="to-translate transition-x"
          data-en="eurecap provides you with a competent, trained and efficient project manager:"
          data-de="Eurecap bietet Ihnen kompetente, ausgebildete und effiziente Mitarbeitende:"
        >
          eurecap vous propose une cheffe de projet compétente et efficace:
        </p>
        <hr />
        <p>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12.511"
              height="9.587"
              viewBox="0 0 12.511 9.587"
            >
              <path
                d="M7.176,14.887,3.3,11.011l.952-.952,2.924,2.924L14.859,5.3l.952.952Z"
                transform="translate(-3.3 -5.3)"
                fill="#414141"
              />
            </svg>
            <span
              class="to-translate transition-x"
              data-en="Short- or medium-term assignments"
              data-de="Kurz- oder mittelfristige Einsätze"
            >
              Missions courtes ou moyenne durée
            </span>
          </span>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12.511"
              height="9.587"
              viewBox="0 0 12.511 9.587"
            >
              <path
                d="M7.176,14.887,3.3,11.011l.952-.952,2.924,2.924L14.859,5.3l.952.952Z"
                transform="translate(-3.3 -5.3)"
                fill="#414141"
              />
            </svg>
            <span
              class="to-translate transition-x"
              data-en="Advice and analysis systematically used to support the action"
              data-de="Beratung und Analyse, die systematisch auf Handlung ausgelegt sind"
            >
              Conseil et analyse systématiquement au service de l’action
            </span>
          </span>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12.511"
              height="9.587"
              viewBox="0 0 12.511 9.587"
            >
              <path
                d="M7.176,14.887,3.3,11.011l.952-.952,2.924,2.924L14.859,5.3l.952.952Z"
                transform="translate(-3.3 -5.3)"
                fill="#414141"
              />
            </svg>
            <span
              class="to-translate transition-x"
              data-en="Collaboration with your usual service providers (advertising agencies, graphic designers, printers)"
              data-de="Zusammenarbeit mit Ihren üblichen Dienstleistern (Werbeagenturen, Grafiker:innen, Druckereien)"
            >
              Collaboration avec vos prestataires habituels (agence de
              publicité, graphistes, imprimeurs…)
            </span>
          </span>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12.511"
              height="9.587"
              viewBox="0 0 12.511 9.587"
            >
              <path
                d="M7.176,14.887,3.3,11.011l.952-.952,2.924,2.924L14.859,5.3l.952.952Z"
                transform="translate(-3.3 -5.3)"
                fill="#414141"
              />
            </svg>
            <span
              class="to-translate transition-x"
              data-en="Turnkey solution with our partners, if the client so wishes"
              data-de="Auf Wunsch massgeschneiderte Lösungen mit unseren eigenen Partnern"
            >
              Solution clé en main avec nos partenaires si souhaité
            </span>
          </span>
        </p>
      </div>
    </section>
    <p
      id="calling-eurecap"
      class="to-translate transition-x"
      data-en="With eurecap you will have a single contact person who is an expert in their field, and is mature and experienced enough to act - even in an emergency - to support the marketing teams in place."
      data-de="Die Zusammenarbeit mit eurecap garantiert Ihnen eine einzige Kontaktperson, die sich in ihrem Bereich bestens auskennt, die auch unter Zeitdruck mit Augenmass und Gelassenheit handelt und damit die internen Marketingteams tatkräftigt unterstützt."
    >
      Faire appel à eurecap, c’est la garantie d’avoir une seule personne de
      contact, experte dans son domaine, avec la maturité et l’expérience pour
      agir, même dans l’urgence, afin de supporter les équipes marketing en
      place.
    </p>
    <BrainStorming class="home-brainstorming"/>
    <TitleView
      :label="'Nos solutions'"
      :title="'L’excellence du marketing client'"
      v-for="(item, index) in TitleItems"
      :key="index"
      :labelEn="item.labelEn"
      :labelDe="item.labelDe"
      :TitleEn="item.TitleEn"
      :TitleDe="item.TitleDe"
    />
    <section id="solution-box-container">
      <HomeCards
        v-for="(item, index) in HomeCardsItems"
        :key="index"
        :src="item.src"
        :title="item.title"
        :paragraphe="item.paragraphe"
        :to="to"
        :text-de="item.TextDe"
        :text-en="item.TexTEn"
        :title-de="item.TitleDe"
        :title-en="item.TitleEn"
      />
    </section>
    <QuoteComponent
      class="quote-home"
      quote="L’avenir n’est jamais que du présent à mettre en ordre."
      autor="Antoine de Saint-Exupéry"
      quoteEn="The future is never more than the present to be put in order."
      quoteDe="Die Zukunft ist immer nur die Gegenwart, die geordnet werden muss."
    />
    <section class="avis-home">
      <!-- Exemples d'utilisation d'un Web Component dans une page -->
      <TitleView
        :label="'Références'"
        :title="'Des témoignages inspirants'"
        class="white"
        v-for="(item, index) in TitleItems2"
        :key="index"
        :labelEn="item.labelEn"
        :labelDe="item.labelDe"
        :TitleEn="item.TitleEn"
        :TitleDe="item.TitleDe"
      />
      <AvisContainer :arrows="false" />
      <EnSavoirPlus where="/references" />
    </section>
  </main>
</template>

<script>
import WordingView from "../components/HomeWording.vue";
import TitleView from "@/components/HomeTitle.vue";
import HomeCards from "@/components/HomeCards.vue";
import AvisContainer from "@/components/avis/AvisContainer.vue";
import QuoteComponent from "@/components/QuoteComponent.vue";
import EnSavoirPlus from "@/components/EnSavoirPlus.vue";
import BrainStorming from "@/components/BrainStorming.vue";

import maxHeight from "@/assets/js/maxHeight";
import imgObserver from "@/assets/js/ImgObserver";

import ImgRocket from "@/assets/img/svg/rocket.svg";
import ImgSuitCase from "@/assets/img/svg/suitcase.svg";
import ImgHands from "@/assets/img/svg/hands.svg";

export default {
  name: "HomeView",
  components: {
    WordingView,
    TitleView,
    HomeCards,
    AvisContainer,
    QuoteComponent,
    EnSavoirPlus,
    BrainStorming
  },
  data() {
    return {
      TitleItems: [
        {
          labelEn: "Our Solutions",
          TitleEn: "Customer marketing excellence",
          labelDe: "Unsere Leistungen",
          TitleDe: "Hervorragendes Kundenmarketing",
        },
      ],
      TitleItems2: [
        {
          labelEn: "References",
          TitleEn: "Inspiring testimonials",
          labelDe: "Referenzen",
          TitleDe: "Interessante Erfahrungsberichte",
        },
      ],
      wordingItems: [
        {
          text: "Marketing relationnel",
          src: ImgRocket,
          en: "Relationship Marketing",
          de: "Beziehungsmarketing",
        },
        {
          text: "Management de projet",
          src: ImgSuitCase,
          en: "Project management",
          de: "Projektmanagement",
        },
        {
          text: "Management de l’interim",
          src: ImgHands,
          en: "Temporary staff management",
          de: "Interim-Management",
        },
      ],
      GridImg: require("../assets/img/stairs-and-computer.png"),
      HomeCardsItems: [
        {
          title: "Marketing relationnel",
          paragraphe:
            "eurecap vous aide à atteindre vos objectifs commerciaux grâce à la mise en place de campagnes marketing ciblées",
          src: ImgRocket,
          TitleEn: "Relationship marketing",
          TexTEn:
            "eurecap helps you to achieve your sales objectives by setting up targeted marketing campaigns",
          TitleDe: "Beziehungsmarketing",
          TextDe:
            "eurecap hilft Ihnen dank der Implementierung von gezielten Marketingkampagnen beim Erreichen Ihrer Geschäftsziele.",
        },
        {
          src: ImgSuitCase,
          title: "Management de projet",
          paragraphe:
            "eurecap propose du personnel qualifié pour la gestion de projets business complexes, notamment à affinité technique.",
          TitleEn: "Project management",
          TexTEn:
            "eurecap provides qualified staff to manage complex business projects, particularly those with technical implications.",
          TitleDe: "Projektmanagement",
          TextDe:
            "eurecap stellt qualifiziertes Personal für das Management komplexer Businessprojekte zur Verfügung, insbesondere mit technischer Ausrichtung.",
        },
        {
          title: "Management de l’interim",
          paragraphe:
            "eurecap met à disposition une cheffe de projet qualifiée pour pallier à un manque de personnel dans votre service commercial ou marketing.",
          src: ImgHands,
          TitleEn: "Temporary staff management",
          TexTEn:
            "eurecap can provide a qualified project manager to fill a gap in your sales or marketing department.",
          TitleDe: "Interim-Management",
          TextDe:
            "eurecap stellt bei Personalmangel in Ihrer Vertriebs- oder Marketingabteilung eine erfahrene Projektleiterin zur Verfügung.",
        },
      ],
    };
  },
  mounted() {
    function setAutoHeight(box) {
      box.style.height = "auto";
    }
    imgObserver(document.querySelectorAll("img"));
    const boxes = document.querySelectorAll(".solution-box");
    window.addEventListener(
      "resize",
      window.innerWidth > 800 ? maxHeight(boxes) : boxes.forEach(setAutoHeight)
    );
  },
};
</script>
